.addpostcard__container {
  /* width: 27%; */
  border: 2px dashed #8d02fa;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 4.66%; */
  margin: 15px;
  margin-top: 20px;
  border-radius: 8px;
  margin-bottom: 4vh;
  min-height: 250px;
  background-color: #fff;
}

.addpostcard_section{
  padding: 10px !important;
    box-shadow: 0px 14px 30px rgb(0 0 0 / 8%);
    width: 100%;
    border-radius: 10px;
    width: 90%;
    margin-left: 5%;
    background-color: white;
    margin-top: 40px;
}
.addpostcard_heading{
    font-weight: bold;
    display: inline-block;
    margin-left: 15px;
    margin-top: 10px;
    font-size: 16px;
 
}

.addpostcard__img {
  margin-left: 50%;
  transform: translateX(-50%);
}

.addpostcard__text {
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 19.5px;
  text-align: center;
  letter-spacing: 2.5px;
  color: #8D02FA;
  font-family: 'Montserrat', sans-serif;
  margin-top: 10px;
}

@media only screen and (max-width: 1000px) {
  .addpostcard__container {
    width: 40%;
  }
}

@media only screen and (max-width: 600px) {
  .addpostcard__container {
    width: 80%;
    margin-left: 10%;
  }
}
@media only screen and (max-width: 600px) {
  .addpostcard__container {
    margin: 0px !important;
    width: 100% !important;
    height: 25vh !important;

  }
  .addpostcard_section{
    padding: 10px !important;
      
      width: 100%;
      margin-left: 0px;
  }
  .addpostcard_heading{
    margin-bottom: 10px;
    margin-left: 0px;
    
}
}