.clients_container {
  padding: 10px 5% 10px 190px;
  box-sizing: border-box;
}


.client_all_search_row {
  padding: 1rem;
  margin-left: 0;
}

.clients_heading {
  font-size: 20.2px;
  font-weight: bold;
  letter-spacing: 0.5px;
  display: inline-block;
  /* margin-left: 5%; */
}

.client_table_div {
  border: 1px solid #d9d5ec;
  width: 100%;
  border-radius: 10px;
  position: relative;
  margin-bottom: 4vh;
  /* margin-left: 5%; */
}

#all_search {
  background-color: #f4f2ff;
}

#clients_fa_filter {
  color: white;
}

#clients_fitler_menu {
  background-color: #8d02fa;
  padding-right: 1%;
}

#clients_filter_menu_drop_down {
  /* position: absolute;  */
    /* top: 4rem; */
    /* left: 17rem; */
    /* z-index: 1000; */
    display: block;
    /* float: left; */
    min-width: 30rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 0.875rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;

}

@media screen and (max-width: 990px) {
  .client_table_div {
    width: 45%;
  }
}

@media screen and (max-width: 760px) {
  .client_table_div {
    width: 90%;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }

  #all_search {
    /* width: 12rem; */
    /* margin-bottom: 1rem; */
  }

  .client_all_search_row ul {
    margin-left: -2rem;
    width: 100%;
  }

  #clients_fitler_menu {
    position: absolute;
    top: 1.5rem;
    right: 5rem;
  }

  #clients_filter_menu_drop_down {
    min-width: 100%;
    top: -2rem;
    left: 0rem;
  }

  #filter_apply_button {
    margin-left: 8rem !important;
  }

  .dropdown-menu-arrow {
    left: 17.5rem !important;
  }
  .client_filter_input {
    width: 100%;
  }
  .client_all_search_row {
    position: relative;
  }
  .client_all_search_row ul li {
    display: block !important;
    margin-bottom: 5px;
    width: 100%;
  }

  #reset_button {
    margin-right: 15rem;
  }

  .clientlistingtable_table_td {
    vertical-align: middle !important;
  }

  .clients_right_side_bar_view {
    /* top: 0 !important; */
    right: 0rem !important;
    width: 100% !important;
  }

  .clients_side_view_details {
    padding: 0 1rem !important;
  }
}

@media only screen and (max-width: 990px) {
  .clients_container {
    width: 100%;
  }
}

#filter_apply_button {
  background-color: #8d02fa;
  color: #fff;
  margin-left: 75%;
  width: 5rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

#filter_cancel_button {
  /* background-color: #8d02fa; */
  border: 1px solid #8d02fa;
  color: #8d02fa;
  margin-left: 1rem;
  width: 5rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.clients_filter_badge {
  color: #626164;
  background-color: #f4f2ff;
  /* border: 1px solid #8d02fa; */
  border-radius: 4px;
  /* text-align: center; */
  font-size: 15px;
  font-weight: bolder;
  height: 35px;
  margin-bottom: 4px;
  /* padding-left: 5px; */
  display: flex;
  align-items: center;
}

.dropdown-menu-arrow {
  top: -1.5rem;
  left: 3rem;
  width: 0;
  height: 0;
  position: relative;
}

.dropdown-menu-arrow:before,
.dropdown-menu-arrow:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 7px 8px;
  border-style: solid;
  border-color: transparent;
  z-index: 1001;
}

.dropdown-menu-arrow:after {
  bottom: -18px;
  right: -8px;
  border-bottom-color: #fff;
}

.dropdown-menu-arrow:before {
  bottom: -17px;
  right: -8px;
  border-bottom-color: rgba(0, 0, 0, 0.15);
}

.client_filter_input {
  border: 1px solid #dbd9ea;
  height: 37px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin: 0;
  border-radius: 5px;
  width: 100%;
}

.table-footer {
  position: relative;
  height: 50px;
  background-color: #f4f2ff;
}

.client_all_search_row ul {
  list-style: none;
}

.client_all_search_row ul li {
  display: inline;
  margin-right: 10px;
  vertical-align: middle;
  padding: 10px;
}

.clients_filter_badge .fa-close {
  background-color: #858585;
  height: 20px;
  width: 20px;
  border-radius: 1000px;
  color: white;
  vertical-align: middle;
  line-height: 1.5;
  cursor: pointer;
}

.table-footer .text-primary {
  font-size: 1.2rem;
  font-weight: 500;
  color: #8d02fa !important;
  text-transform: uppercase;
}

.client-action-btn{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.client-action-btn .btn{
  margin: 0px 5px;
}
.client-search-div{
  display: flex;
  align-items: center;
}
.profile-select .MuiInputLabel-outlined{ 
  transform: translate(14px, 14px) scale(1);
}

.profile-select .MuiOutlinedInput-input  {
  padding: 8.5px 14px;
  font-size: 12px;
}
.profile-select .MuiInputBase-root{
  font-weight: 500px;
}
@media only screen and (max-width:600px){
  .clients_container {
   padding: 5px;
  }
  .client-action-btn{
    justify-content: flex-end;
    /* margin: 10px 0px !important; */
  }
  .client-action-btn .btn{
    margin: 0px 0px 0px 5px;
  }
  .client-search-div{
    justify-content: space-between;
  }
  .client-head{
  margin: 0px 22px 0px 22px;
  }
}