.selectpostpopup__container__uploadText {
  width: 70%;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 999;
  padding-bottom: 15px;
  border-radius: 5px;
}

.uploadFilePopup__backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}

.uploadContent__header {
  padding: 25px;
  margin: 40px 30px;
  /* cursor: pointer; */
}

.highLight {
  text-decoration: underline;
  color: #8d00fa;
  font-weight: 600;
}
