.headeroption__container {
  margin-left: 5%;
  padding-bottom: 10px;
  cursor: pointer;
}



.headeroption__text {
  margin-left: 10px;
}

@media only screen and (max-width: 750px) {
  .headeroption__container {
    font-size: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .headeroption__text {
    font-size: 12px;
  }
}

