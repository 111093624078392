.editaudienceform__field {
  margin-left: 5%;
  margin-top: 40px;
}

.editaudienceform__label {
  font-weight: bold;
  display: block;
}

.editaudienceform__input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: 1px solid #CCCCCC;
  width: 96%;
  height: 40px;
  border-radius: 2px;
  margin-left: 1%;
  padding-left: 10px;
}


.editaudienceform__input:focus {
  outline: none;
}


.editaudienceform__input--number {
  border: 1px solid #CCCCCC;
  padding-left: 5px;
  border-radius: 2px;
}

.editaudienceform__input--select {
  width: 90%;
  margin-left: 1%;
}

.editaudienceform__para {
  /* color: #999; */
  /* font: 1px; */
  margin-top: 10px;
  width: 90%;
  margin-left: 1%;
}

.editaudienceform__field.field__devices::after {
  position: absolute;
  bottom: 0;
  content: "";
  width: 90%;
  height: 1px;
  background-color: #DEE2E6;
}

.editaudienceform__input--check {
  width: 15px !important;
  height: 15px !important;
  visibility: visible !important;
  margin-left: 5px !important;
}
.editaudienceform_checkbox_field_box{
  margin-left: 1%
}

@media only screen and (max-width:600px){
  .editaudienceform_checkbox_field_box{
    margin-left: 0px;
    display: flex;
    flex-direction: column;
  }
}