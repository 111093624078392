.brandprofilecard__container {
  width: 27%;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  position: relative;
  margin-bottom: 4vh;
  background-color: #fff;
  margin-left: 5%;
}

.brandprofilecard__heading {
  font-size: 20.2px;
  font-weight: bold;
  letter-spacing: .5px;
  display: inline-block;
  margin-left: 7%;
  margin-top: 4vh;
}

.brandprofilecard__bytes {
  font-size: 14px;
  font-weight: bolder;
  position: absolute;
  top: 0;
  right: 0;
}

.brandprofilecard__smalltext {
  font-size: 13px;
  color: #A4A2A2;
  font-weight: 500;
  display: inline-block;
}

.brandprofilecard__row--buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.brandprofilecard__btn--white {
  border-radius: 36px;
  font-size: 11px;
  font-weight: 600;
  padding: 8px 24px;
  color: #8D02FA;
  border: 1px solid #8D02FA;
  background-color: #fff;
  cursor: pointer;
}

.brandprofilecard__btn--white:hover {
  background-color: #8C25F6;
  color: #fff;
}

/* Media Queries */

@media screen and (max-width: 990px) {
  .brandprofilecard__container {
    width: 45%;
  }
}

@media screen and (max-width: 760px) {
  .brandprofilecard__container {
    width: 90%
  }
}

@media screen and (max-width: 1200px) and (min-width: 990px) {
  .brandprofilecard__row--buttons {
    flex-flow: column;
    width: 70%;
    height: 15vh;
    margin-left: 15%;
  }
}

@media screen and (max-width: 1200px) and (min-width: 900px) {
  .brandprofilecard__btn--white {
    display: block;
    margin-bottom: 2vh;
  }
}