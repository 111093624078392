.rmf-screen{
    padding-left: 140px;
    background-color: rgb(235 235 235);
    min-height: 90vh;
    padding-right: 20px;
}
.rmf-screen .PrivateTabIndicator-colorSecondary-48 {
    background-color: #b4ff00;
}

.rmf-screen .MuiTab-root{
    text-transform: none;
}

