.prepaidtableheader__container{
  width: 100%;
  padding-bottom: 1%;
  display: flex;
  border-bottom: 3px solid greenyellow;
  margin-top: 20px;
  color: black;
  font-size: 23px;
  font-weight: bolder;
  position: relative;
  height: 50px;
}

.prepaidtableheader__span--country {
  position: absolute;
  left: 2%;
}

.prepaidtableheader__span--tier1 {
  position: absolute;
  left: 17%;
}

.prepaidtableheader__span--tier2 {
  position: absolute;
  left: 33%;
}

.prepaidtableheader__span--tier3 {
  position: absolute;
  left: 50%;
}



.prepaidtableheader__span--users {
  position: absolute;
  left: 65%;
}

.prepaidtableheader__span--action {
  position: absolute;
  left: 90%;
}