.MuiTableHead-root  {
    background-color: #f4f2ff;
  }
  
  .MuiTablePagination-toolbar {
    background-color: #f4f2ff;
  }

  .MuiTable-root {
    font-size: 14px;
    font-weight: 500;
}

.input-container{
    background-color: #f4f2ff;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
}
.input-container .img{
    color: #8d02fa;
}
.input{
    
    outline: none;
    border: none;
    min-width: 360px;
    background-color: rgba(255,255,255,0);
    width: 100%;
}
.input:focus{
    outline: none;
    border: none;
}

.MuiTablePagination-toolbar{
    overflow: hidden;
}

.sort-indicator .MuiSvgIcon-root{
    font-size: 14px;
}

.MuiTable-root .MuiTableCell-head{
    font-size: 14px;
    font-weight: 600;
}
.MuiTable-root .MuiTableCell-body{
    font-size: 14px;
    font-weight: 500;
}
.page .MuiTablePagination-caption{
    font-size: 12px;
    font-weight: 600;
    color: #6E6893;
}

.page .MuiSelect-select.MuiSelect-select,.MuiMenu-list{
    font-size: 12px;
    font-weight: 600;
    color: #6E6893;
}