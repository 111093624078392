.connection-setup-box{
  padding: 20px 20px 20px 140px;
  box-sizing: border-box;
  margin-top: 5vh;
}
.connection-setup-table-container{
  max-width: 1200px;
  margin: auto;
}
.connectionsetup__heading {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}


/* Peasy css */

.connect-acc-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.connect-acc-box {
  overflow: scroll;
  max-height: 400px;
  margin: 14px 15px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-color: #e6e6e6;
  border-radius: 5px;
}

.connect-acc-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 15px 16px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
  }
  
  .connect-acc-list.title-label {
    background-color: #f2f2f2;
    font-weight: 700;
  }
  
  .acc-toggle {
    margin-top: -15px;
  }
  
  .acc-id {
    width: 75px;
    margin-right: 10px;
    font-size: 12px;
  }
  
  .acc-name {
    width: 100px;
    font-size: 12px;
  }
  
  .acc-currency {
    width: 75px;
    font-size: 12px;
  }
  
  .acc-action {
    width: 50px;
    font-size: 12px;
  }
  
  .acc-action.not-eligible {
    color: red;
    text-align: center;
  }
  
  .div-block-73 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
  
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}

.connect-acc-list.title-label {
  background-color: #f2f2f2;
  font-weight: 700;
}

.acc-toggle {
  margin-top: -15px;
}

.acc-id {
  width: 75px;
  margin-right: 10px;
  font-size: 12px;
}

.acc-name {
  width: 100px;
  font-size: 12px;
}

.acc-currency {
  width: 75px;
  font-size: 12px;
}

.acc-action {
  width: 50px;
  font-size: 12px;
}

.acc-action.not-eligible {
  color: red;
  text-align: center;
}

.div-block-73 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.group-horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.connect-acc-label {
  margin-top: 4px;
  margin-bottom: 4px;
}

.ga-acc-box {
  max-height: 400px;
  margin: 14px 15px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-color: #e6e6e6;
  border-radius: 5px;
}

.div-block-75 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 600px){
  .connection-setup-box{
    padding: 10px;
    box-sizing: border-box;
    margin-top: 0px;
  }
}