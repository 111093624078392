.singleaudience__container {
  margin-top: 20px;
  display: flex;
}

.singleaudience__checkbox {
  width: 18px !important;
  height: 18px !important;
  position: relative;
  margin-left: 1%;
  /* margin-top: 28px; */
}

.singleaudience__checkbox::before {
  content: "\00a0";
  height: 18px;
  width: 18px;
  visibility: visible;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #CECBCB;
}

.singleaudience__checkbox:checked::before {
  content: "";
  height: 18px;
  width: 18px;
  visibility: visible;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #B4FF00;
  font-size: 20px;
}

.singleaudience__checkbox:checked::after {
  content: "\2713";
  color: #B4FF00;
  position: absolute;
  top: -29px;
  visibility: visible;
  font-size: 42px;
  left: 0px;
}

.singleaudience__box {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 3%;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 3%;
  padding-right: 0px;
}

.singleaudiencebox__input--budget {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: 1px solid #C4C4C4;
  border-radius: 4px;
  width: 46px;
  height: 24px;
  margin-top: 20px;
  text-align: left;
  position: relative;
  left: 15%;
}

.singleaudiencebox__input--percentage {
  width: 35px;
  margin-left: 10px;
  margin-top: -1px;
  padding: 0px !important;
  text-align: left;
  border: 0px solid #A5A5A5;
  /* color: #A5A5A5; */
  border-bottom-width: 1px;
  color: #708090;
  font-weight: 500;
}


.singleaudiencebox__span--name {
  color: #179BFB;
  display: inline-block;
  width: 15%;
}

.singleaudiencebox__span--description {
  margin-left: 3%;
  width: 12%;
  display: inline-block;
  word-wrap: break-word;
}


.singleaudiencebox__span--budget {
  margin-left: 2%;
  width: 10%;
}

.singleaudiencebox__div--channel {
  /* margin-left: 5%; */
  display: flex;
  margin-top: -23px;
  width: 20%;
  position: relative;
}


.singleaudiencebox__select {
  background-color: #fff;
  border: none;
  position: absolute;
  left: 68%;
  top: 20px;
}

.singleaudiencebox__span--population {
  margin-left: 6%;
  display: inline-block;
  width: 75px;
}

.singleaudiencebox__span--cpa {
  margin-left: 6%;
}

.singleaudience__span--vol {
  margin-left: 4%;
}

.singleaudience__img--dropdown {
  position: absolute;
  width: 15px;
  height: 15px;
  right: -2%;
  top: 30px;
  cursor: pointer;
}

.channel-select .MuiOutlinedInput-input{
  padding: 1.8px 14px;
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .singleaudiencebox__div--channel {
    left: 2%;
  }

  .singleaudiencebox__select {
    left: 61%;
  }

  .singleaudiencebox__input--budget {
    left: 3%;
  }

  .singleaudiencebox__span--cpa {
    margin-left: 6%;
  }
}


@media only screen and (min-width: 1300px) and (max-width: 1400px) {

  .singleaudiencebox__span--budget {
    margin-left: 3%;
  }

  .singleaudiencebox__div--channel {
    left: 1%;
  }

  .singleaudiencebox__select {
    left: 61%;
  }

  .singleaudiencebox__input--budget {
    left: 3%;
  }

  .singleaudiencebox__span--population {
    margin-left: 5%;
  }
}


@media only screen and (min-width: 1400px) and (max-width: 1500px) {

  .singleaudiencebox__span--budget {
    margin-left: 3%;
  }

  .singleaudiencebox__div--channel {
    left: 2%;
  }

  .singleaudiencebox__select {
    left: 61%;
  }

  .singleaudiencebox__input--budget {
    left: 3%;
  }

  .singleaudiencebox__span--population {
    margin-left: 6%;
  }

  .singleaudience__span--vol {
    margin-left: 5%;
  }
}



@media only screen and (min-width: 1500px) and (max-width: 1600px) {

  .singleaudiencebox__span--budget {
    margin-left: 3%;
  }

  .singleaudiencebox__div--channel {
    left: 2%;
  }

  .singleaudiencebox__select {
    left: 61%;
  }

  .singleaudiencebox__input--budget {
    left: 3%;
  }

  .singleaudiencebox__span--population {
    margin-left: 7%;
  }

  .singleaudience__span--vol {
    margin-left: 5%;
  }
}


@media only screen and (min-width: 1600px) and (max-width: 1700px) {

  .singleaudiencebox__span--budget {
    margin-left: 3%;
  }

  .singleaudiencebox__div--channel {
    left: 2%;
  }

  .singleaudiencebox__select {
    left: 61%;
  }

  .singleaudiencebox__input--budget {
    left: 3%;
  }

  .singleaudiencebox__span--population {
    margin-left: 7%;
  }

  .singleaudiencebox__span--cpa {
    margin-left: 7%;
  }

  .singleaudience__span--vol {
    margin-left: 5%;
  }
}


@media only screen and (min-width: 1700px) and (max-width: 1800px) {

  .singleaudiencebox__span--budget {
    margin-left: 3%;
  }

  .singleaudiencebox__div--channel {
    left: 2%;
  }

  .singleaudiencebox__select {
    left: 61%;
  }

  .singleaudiencebox__input--budget {
    left: 3%;
  }

  .singleaudiencebox__span--population {
    margin-left: 7%;
  }

  .singleaudiencebox__span--cpa {
    margin-left: 7%;
  }

  .singleaudience__span--vol {
    margin-left: 6%;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 1900px) {

  .singleaudiencebox__span--budget {
    margin-left: 3%;
  }

  .singleaudiencebox__div--channel {
    left: 2%;
  }

  .singleaudiencebox__select {
    left: 61%;
  }

  .singleaudiencebox__input--budget {
    left: 3%;
  }

  .singleaudiencebox__span--population {
    margin-left: 7%;
  }

  .singleaudiencebox__span--cpa {
    margin-left: 7%;
  }

  .singleaudience__span--vol {
    margin-left: 6%;
  }
}


@media only screen and (min-width: 1900px) and (max-width: 2000px) {

  .singleaudiencebox__span--budget {
    margin-left: 3%;
  }

  .singleaudiencebox__div--channel {
    left: 2%;
  }

  .singleaudiencebox__select {
    left: 61%;
  }

  .singleaudiencebox__input--budget {
    left: 3%;
  }

  .singleaudiencebox__span--population {
    margin-left: 7%;
  }

  .singleaudiencebox__span--cpa {
    margin-left: 8%;
  }

  .singleaudience__span--vol {
    margin-left: 6%;
  }
}


@media only screen and (min-width: 2000px) and (max-width: 2100px) {

  .singleaudiencebox__span--budget {
    margin-left: 3.5%;
  }

  .singleaudiencebox__div--channel {
    left: 2%;
  }

  .singleaudiencebox__select {
    left: 61%;
  }

  .singleaudiencebox__input--budget {
    left: 3%;
  }

  .singleaudiencebox__span--population {
    margin-left: 7%;
  }

  .singleaudiencebox__span--cpa {
    margin-left: 8%;
  }

  .singleaudience__span--vol {
    margin-left: 6%;
  }
}



@media only screen and (min-width: 2100px) and (max-width: 2200px) {

  .singleaudiencebox__span--budget {
    margin-left: 3.5%;
  }

  .singleaudiencebox__div--channel {
    left: 2%;
  }

  .singleaudiencebox__select {
    left: 61%;
  }

  .singleaudiencebox__input--budget {
    left: 3%;
  }

  .singleaudiencebox__span--population {
    margin-left: 7%;
  }

  .singleaudiencebox__span--cpa {
    margin-left: 8%;
  }

  .singleaudience__span--vol {
    margin-left: 7%;
  }
}


@media only screen and (min-width: 2200px) and (max-width: 2300px) {

  .singleaudiencebox__span--budget {
    margin-left: 4%;
  }

  .singleaudiencebox__div--channel {
    left: 2%;
  }

  .singleaudiencebox__select {
    left: 61%;
  }

  .singleaudiencebox__input--budget {
    left: 3%;
  }

  .singleaudiencebox__span--population {
    margin-left: 7%;
  }

  .singleaudiencebox__span--cpa {
    margin-left: 8%;
  }

  .singleaudience__span--vol {
    margin-left: 7%;
  }
}

@media only screen and (min-width: 2300px) and (max-width: 2400px) {

  .singleaudiencebox__span--budget {
    margin-left: 4%;
  }

  .singleaudiencebox__div--channel {
    left: 2%;
  }

  .singleaudiencebox__select {
    left: 61%;
  }

  .singleaudiencebox__input--budget {
    left: 3%;
  }

  .singleaudiencebox__span--population {
    margin-left: 7%;
  }

  .singleaudiencebox__span--cpa {
    margin-left: 8.5%;
  }

  .singleaudience__span--vol {
    margin-left: 8%;
  }
}


@media only screen and (min-width: 2400px) and (max-width: 2500px) {

  .singleaudiencebox__span--budget {
    margin-left: 4%;
  }

  .singleaudiencebox__div--channel {
    left: 2%;
  }

  .singleaudiencebox__select {
    left: 61%;
  }

  .singleaudiencebox__input--budget {
    left: 3%;
  }

  .singleaudiencebox__span--population {
    margin-left: 7%;
  }

  .singleaudiencebox__span--cpa {
    margin-left: 8.5%;
  }

  .singleaudience__span--vol {
    margin-left: 8%;
  }
}

@media only screen and (min-width: 2500px) and (max-width: 2600px) {

  .singleaudiencebox__span--description {
    margin-left: 4%;
  }

  .singleaudiencebox__span--budget {
    margin-left: 3.5%;
  }

  .singleaudiencebox__div--channel {
    left: 3%;
  }

  .singleaudiencebox__select {
    left: 61%;
  }

  .singleaudiencebox__input--budget {
    left: 3%;
  }

  .singleaudiencebox__span--population {
    margin-left: 7%;
  }

  .singleaudiencebox__span--cpa {
    margin-left: 9%;
  }

  .singleaudience__span--vol {
    margin-left: 8.5%;
  }
}


/* @media only screen and (min-width: 1300px) and (max-width: 1400px) {
  .singleaudiencebox__div--channel {
    left: 2%;
  }
} */

/* @media only screen and (min-width: 1500px) {
  .singleaudiencebox__select {
    left: 75%;
  }

  .singleaudiencebox__span--population {
    margin-left: 8%;
  }
}
 */
/* @media only screen and (min-width: 1600px) {
  .singleaudience__span--vol {
    margin-left: 5.5%;
  }
}

@media only screen and (min-width: 1700px) {
  .singleaudiencebox__select {
    left: 87%;
  }
}


@media only screen and (min-width: 1750px) {
  .singleaudiencebox__span--cpa {
    margin-left: 7%;
  }


  .singleaudiencebox__span--budget {
    margin-left: 3%;
  }

} */

.budget-percent:active,.budget-percent:focus{
  border: none;
  outline: none;
}