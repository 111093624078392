/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap"); */
.clients_right_side_bar_view {
  position: fixed;
  z-index: 100;
  right: 0;
  top: 135px;
  height: 82%;
  width: 40%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.clients_right_side_bar_view .container {
  overflow-y: auto;
  /* height: 500px; */
  padding-bottom: 20px;
  box-sizing: border-box;
}

.clients_right_side_bar_view .clients_right_side_view_close {
  border-radius: 0px 0px 30px 0px;
  background-color: #b4ff00;
  width: 40px;
  height: 31px;
  cursor: pointer;
}

.clients_right_side_bar_view .clients_right_side_view_close .fa-minus {
  color: white;
}

.clients_right_side_bar_view .clients_right_side_modal_header {
  background-color: #f4f2ff;
  width: 100%;
  height: 100px;
}
.clients_right_side_bar_view h2,
.clients_right_side_bar_view .fa-pencil {
  color: #8d02fa;
}

.clients_side_view_details {
  /* padding-right: 1%;
  padding-left: 1%; */
  padding: 0rem 1rem;
}

.clients_side_view_details .btn-cancel {
  background-color: none;
  border: 1px solid purple;
  color: purple;
  border-radius: 20px;
}

.clients_side_view_details .btn-apply {
  background-color: #8d02fa;
  color: white;
  border-radius: 20px;
}

.clients_side_view_details .col-sm-4 {
  width: 35%;
  word-wrap: break-word;
}

.clients_side_view_details .col-sm-8 {
  width: 65%;
}

/* #ad_table {
  width: 10rem;
  height: 10rem;
} */

.text-key-details {
  font-size: 0.9rem;
  font-weight: 600;
  color: #212121;
  line-height: 1.5rem;
  text-align: left;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clients_right_side_bar_view .text-right {
  color: #212121;
}

.renderviewmoremodal_loader {
  margin: auto;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #8d02fa;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@media only screen and (max-width:600px){
  .clients_right_side_bar_view .container {
    padding-bottom: 20px;
  }
}