.prepaidtablerow__container{
  width: 100%;
  margin-top: 10px;
  color: rgb(172, 169, 169);
  font-size: 15px;
  font-weight:500;
  position: relative;
  height: 50px;
  margin-bottom: 26px;
}

.prepaidtablerow__span--percent {
  margin-left: 40%;
}

.prepaidtablerow__span--country {
  position: absolute;
  left: 3%;
}

.prepaidtablerow__span--tier1 {
  position: absolute;
  left: 18%;
}

.prepaidtablerow__span--tier2 {
  position: absolute;
  left: 33%;
}

.prepaidtablerow__span--tier3 {
  position: absolute;
  left: 50%;
}


.prepaidtablerow__span--users {
  position: absolute;
  left: 72%;
}

.prepaidtablerow__span--action {
  position: absolute;
  left: 92%;
}