.predictionform__container {
  display: flex;
  width: 96%;
  margin-left: 2%;
  justify-content: space-between;
  padding-bottom: 50px;

}

.predictionform__input--container {
  width: 22%;
}

.predictionform__label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}


.predictionform__input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: 1px solid #CECBCB;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  color: #708090;
  font-weight: bold;
  padding-left: 5%;
}


@media only screen and (max-width: 500px) {
  .predictionform__container {
    display: block;
  }

  .predictionform__input--container {
    width: 100%;
    margin: 0px;
  }

  .predictionform__label {
    margin-top: 20px;
  }

}