body {
  /* font-family: "Montserrat", sans-serif !important; */
  padding-top: 80px;
}

h2 {
  font-size: 24px;
  font-weight: bold;
}

h3 {
  font-size: 15px;
  font-weight: bold;
}

h4 {
  font-size: 14px;
  font-weight: bold;
}

h5 {
  font-size: 12px;
  color: #999;
  font-weight: bold;
}

.bg-grey {
  background: #f9f9f9;
}

.text-12 {
  font-size: 12px;
}

form .card {
  border: 0px;
}

form .card-header {
  background: white;
  border: 0px;
}

form .card-body {
  padding-top: 1px;
}

form .card-body .table {
  font-size: 12px;
}

.card.channel-select .card-body {
  height: 400px;
  overflow-y: scroll;
}

/* input[type="text"] {
  padding-top: 22px;
  padding-bottom: 22px;
} */

.table th,
.table thead {
  background: #00000008;
  border: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.card.channel {
  padding: 10px 5px;
  border: 1px solid #ccc;
}

.btn.btn-primary {
  background: #8d02fa;
  color: white;
  border: 0px;
  border-radius: 20px;
  font-weight: 500;
}

.btn.btn-outline-primary {
  border: 1px solid #8d02fa;
  color: #8d02fa;
  border-radius: 20px;
  font-weight: 500;
}

.btn.btn-outline-primary:hover {
  border: 1px solid #8d02fa;
  background: #8d02fa;
  color: white;
  border-radius: 20px;
}

.btn.btn-primary:active {
  background: #8d02fa !important;
  border: 0px !important;
}

.btn.btn-secondary {
  background: #9ed229;
  color: black;
  border: 0px;
  border-radius: 20px;
  font-size: 12px;
  padding: 8px 20px;
}

.btn.btn-disabled {
  background: #6c757d;
  color: white;
  border: 0px;
  border-radius: 20px;
  font-size: 12px;
  padding: 8px 20px;
  pointer-events: none;
}

.btn.btn-light {
  background: white;
  color: black;
  border: 1px;
  border-color: black;
  border-radius: 20px;
  font-size: 12px;
  padding: 8px 20px;
}

ul.campaign-navigation {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

ul.campaign-navigation li {
  margin: 50px 0px;
}

ul.campaign-navigation li.active,
ul.campaign-navigation li.active a {
  font-weight: bold;
  color: #8d02fa !important;
}

ul.campaign-navigation.campaign-edit {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  /* font-family: "Montserrat", sans-serif !important; */
}

.form-check-inline {
  border: 2px solid #9ed229;
  padding: 10px;
  border-radius: 50px;
  font-weight: bold;
  margin-bottom: 5px;
  width: 150px;
}

.form-check-inline.longer {
  width: 180px;
}

.form-check-inline.selected {
  background: #d2ff66;
}

.form-check-inline .channel-icon {
  width: 20px;
  display: inline;
  margin-right: 10px;
}

.form-check-inline.auto-width {
  width: auto;
}

.input-group-text {
  background: white;
}

.field-description {
  color: #999;
}

.tokens-container .token {
  background: greenyellow !important;
  border: none !important;
  border-radius: 50px !important;
  font-weight: bold;
}

.tokens-container .token .dismiss {
  color: #333 !important;
}

.bg-peasy-logo {
  background: greenyellow;
}

.text-small {
  font-size: 12px;
}

.badge-channel-active {
  padding: 12px 22px;
  font-size: 15px;
  border-radius: 50px;
  background: #8d02fa;
  color: white;
}

.btn-integration {
  width: 250px;
}

table.table-mediaplan td {
  border: 0px;
  text-transform: capitalize;
}

table.table-mediaplan th {
  border: 0px;
  color: #999;
}

table.table-mediaplan .tr-summary td {
  background: #f8ffe8;
  font-weight: bold;
}

table.table-mediaplan input {
  text-align: center;
}

table tbody {
  border: 0px !important;
}

.fa {
  margin-right: 12px;
}

.fa-toggle-on.active {
  color: #8d02fa;
}

.fa-toggle-off {
  color: #999;
}

.card.card-trafficking ul.upload-check {
  list-style: none;
  font-size: 14px;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}

.card.card-trafficking .card-header {
  background: none;
  border: 0px;
}

.card.card-trafficking ul.upload-check li {
  color: #ccc;
}

.card.card-trafficking ul.upload-check li.active {
  color: green;
}

.card.card-trafficking h3 {
  font-size: 18px;
}

table.table-value {
  font-size: 12px;
}

table.table-value td {
  border: none;
}

table.table-value td:first-child {
  color: #999;
}

table.table-value td:nth-child(2) {
  text-align: right;
  font-weight: bold;
}

.username-icon {
  font-size: 20px;
  margin-right: 2px;
}

a {
  color: black;
}

a:hover {
  color: black;
}

table.table-peasy-big {
}

table.table-peasy-big tr {
}

table.table-peasy-big tr td {
}

#new-campaign-selection {
}

#new-campaign-selection .col .col-item {
  text-align: center;
  height: 240px;
}

.container-fluid.basic-layout {
  margin-top: -85px;
}

.container-fluid.basic-layout .row {
  height: 100vh;
}

.container-fluid.basic-layout .row .col.jumbotron {
  background-image: url("/images/assets/reg_jumbo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position-y: -30px; */
  margin: 0px;
}
.container-fluid.basic-layout .row .col.grant {
  background-image: url("/images/assets/grant.jpg");

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 0px;
  margin: 0px;
}

.navbar.navbar-absolute {
  position: absolute;
  width: 100%;
}

.card.card-login {
  margin-top: 20vh;
}

.input-budget-split {
  text-align: center;
  width: 45px;
}

.log-container {
  border: 1px solid #ccc;
  height: 300px;
  overflow-y: scroll;
}

.input-validation-error {
  font-size: 14px;
  font-family: Nunito;
  color: red;
  margin-top: 5px;
  background: rgba(255, 0, 0, 0.07);
  padding: 8px;
}

.input-validation-error a {
  color: red;
  text-decoration: underline;
}

.list-group.notification {
  font-size: 10px;
  width: 280px;
}

.btn-outline-primary.dropdown-toggle:focus {
  background: #8d02fa;
}

.dropdown-toggle {
  font-size: 12px;
}

.dropdown-menu {
  padding: 0px;
}

.list-group.campaign-draft {
  font-size: 12px;
  width: 300px;
}

.btn.btn-link {
  text-decoration: none;
  color: #666;
  font-weight: bold;
}

.fa-trash-o {
  font-size: 15px;
}

.list-group.notification {
  height: 500px;
  overflow-y: scroll;
}

/* input[type="text"]:read-only {
  background: #ddd;
  color: #666;
}

input[type="text"]:disabled {
  background: #ddd;
  color: #666;
}

input[type="text"]:read-only.readonly-bg-white {
  background: white !important;
}

input[type="number"] {
  width: 40px;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

.report-menu {
  padding: 20px 0px;
  padding-bottom: 15px;
}

.report-header {
  background: white;
  padding: 25px 0px;
  box-shadow: 1px 1px 1px #ddd;
}

.report-brand {
  color: #9ed229;
  font-weight: bold;
  font-style: italic;
  font-size: 14px;
}

h1.report-campaign-name {
  margin-bottom: 4px;
  font-size: 32px;
}

.badge.badge-report {
  background: greenyellow;
  color: darkgreen;
  margin-left: 10px;
  padding: 6px 25px;
  font-style: normal;
  border-radius: 15px;
}

.fa-toggle-on.report {
  font-size: 38px;
  color: #8d02fa;
  margin-top: 15px;
}

.fa-toggle-off.report {
  font-size: 38px;
  margin-top: 15px;
}

.container.container-report h3 {
  margin-bottom: 2px;
}

.container.container-report h4 {
  margin-bottom: 4px;
}

.container.container-report .h-sub {
  font-size: 11px;
  color: #999;
}

.report-cell {
  font-weight: bold;
}

.report-cell-caption {
  margin-bottom: 5px;
  font-size: 12px;
  color: #999;
}

.report-cell-value {
  color: #999;
}

.funnel {
}

.funnel-item {
  text-align: center;
  border: 1px solid #ccc;
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(#eee, white);
}

.funnel-item:last-child {
  border-bottom: 1px solid #ccc;
}

.funnel-item-marker {
  height: 120px;
  background: #d2ff66;
}

.funnel-item-caption {
  position: absolute;
}

.funnel-item-caption {
}

.funnel-item-caption-sub {
  font-size: 11px;
  color: #666;
}

.table.table-report thead {
  background: none !important;
}

.table.table-report th {
  background: none !important;
  border-top: none;
}

.table.table-report td {
  border-top: none;
  font-size: 12px;
  text-transform: capitalize;
}

.table.table-report thead th:first-child {
  color: black;
}

.table.table-report thead th {
  border-bottom: none !important;
  color: #bbb;
}

ul.campaign-list {
  padding: 0;
  margin: 0;
}

ul.campaign-list li {
  display: flex;
  flex-direction: row;
  border: 1px solid #eee;
  height: 80px;
  align-items: center;
  margin-top: 10px;
  background: white;
}

ul.campaign-list li div {
  padding: 12px;
}

ul.campaign-list li div.objective {
  flex-basis: 50px;
}

ul.campaign-list li div.status {
  flex-basis: 120px;
}

ul.campaign-list li div.name {
  flex-basis: 350px;
}

ul.campaign-list li div.budget {
  flex-basis: 300px;
}

ul.campaign-list li div.target {
  flex-basis: 300px;
}

ul.campaign-list li div.action {
  flex-grow: 1;
}

nav.navbar {
  position: fixed;
  width: 100%;
  top: 0;
}

.stickynote {
  background: #8d02fa;
  color: white;
  padding: 10px 50px;
  font-size: 11px;
}

#mobilescreen-cover {
  top: 0px;
  left: 0px;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ececec;
  z-index: 10000000;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.jquery-ready {
  display: none;
}

select.form-control.dashboard-filter {
  width: 220px;
  display: inline;
  margin: 0px 15px;
  border: 0px;
  text-align: center;
  font-weight: bold;
}

.row.dashboard-metric h5 {
  color: black;
}

.row.dashboard-metric h1 {
  font-size: 28px;
  margin-bottom: 28px;
}

.row.dashboard-metric .col {
  border: 1px solid #ccc;
  height: 200px;
  padding: 14px;
}

.row.dashboard-metric .col.dark {
  color: white !important;
}

.row.dashboard-metric .col.dark h5 {
  color: white !important;
}

.text-peasy-purple {
  color: #8d02fa !important;
}

.bg-peasy-green {
  background: #b4ff00 !important;
}

.bg-peasy-purple {
  background: #8d02fa !important;
}

.bg-peasy-gray {
  background: #4d4e4f !important;
}

.bg-peasy-blue {
  background: #299fd2 !important;
}

.font-color-white {
  color: white !important;
}

.row.dashboard-metric .bg-peasy-green {
  border: #b4ff00 !important;
}

.row.dashboard-metric .bg-peasy-purple {
  border: #8d02fa !important;
}

.row.dashboard-metric .bg-peasy-blue {
  border: #299fd2 !important;
}

#daily-report {
  height: 500px;
}

.card.card-channel-uploader {
  border: 1px solid #ccc;
}

.card.card-channel-uploader .card-body {
  padding-top: 15px;
}

.card.card-channel-uploader .card-body h5 {
  font-size: 14px;
}

.card.card-channel-uploader .card-body p {
  font-size: 10px;
  margin-top: 8px;
}

.card.card-channel-uploader .card-header {
  border-bottom: 1px solid #ccc;
}

.ads-preview-in-row {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  flex-direction: row;
  height: 220px;
}

.ads-preview-in-row > div {
  border: 1px solid #eee;
  text-align: center;
  width: 220px;
  padding: 10px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ads-preview-in-row.ads-carousel {
  height: 280px;
}

.ads-preview-in-row.ads-carousel > div {
  width: 250px;
}

.ads-carousel-image {
  margin-bottom: 40px;
}

.ads-carousel-body {
  font-size: 10px;
  text-align: left;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.dummy-button {
  border: 1px solid #999;
  background: #eee;
  font-size: 11px;
  padding: 5px;
}

.overflow-x {
  overflow-x: auto;
}

.card-foldable .card-header {
  cursor: pointer;
}

.thumbnail-preview {
  max-width: 100%;
  max-height: 190px;
}

.text-peasy-success {
  color: yellowgreen !important;
}

.text-peasy-danger {
  color: red !important;
}

.video-preview {
  cursor: pointer;
}

.register__form-container {
  height: 100vh;
  overflow: scroll;
}

.register__nav {
  z-index: 0;
}

.register__form {
  height: auto;
}

.register__step-image {
  width: 350px;
  padding: 20px;
}

@media (min-width: 992px) {
  .navbar {
    box-shadow: 1px 1px 3px #ddd;
    padding: 0px;
    height: 80px;
    z-index: 100;
    background-color: #fff;
  }

  .navbar-brand img {
    margin-left: -20px;
  }

  #mobilescreen-cover {
    display: none;
  }
}

input.error,
select.error,
textarea.error {
  border: 1px solid red;
  color: black;
  margin-top: 5px;
  /* background: rgba(255, 0, 0, 0.07); */
  padding: 8px;
}

.form-group.has-error .help-block {
  display: block;
  color: red;
}

.error {
  color: red;
}

#error {
  color: red !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width:600px){
  body{
    padding-top: 55px;
  }
}