@keyframes moveDown {
  0% {
    transform:translateY(-150px) translateX(-50%);
  }
  100% {
    transform:translateY(0) translateX(-50%);
  }
}


.popupalert__container{
  width: 45%;
  background-color: #fff!important;
  position: fixed;
  top: 10vh;
  left: 50vw;
  transform: translateX(-50%);
  z-index: 1050;
  border-radius: 30px;
  animation: moveDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.popupalert__background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, .5);
}
