.UserProfileForm{
  padding: 20px 20px 20px 140px;
  box-sizing: border-box;
  margin-top: 15vh;
}

.UserProfileForm-Main {
  font-size: 22px;
  /* border: 3px solid; */
  text-align: left;
  max-width: 800px;
  margin: auto;
}

.UserProfile-action-buttons{
  display: flex;
  justify-content: flex-end;
}

.UserProfileForm-Main input {
  font-size: 18px;
  font-weight: 700;
  color: rgb(123 123 123);
}

label {
  /* margin: 1%; */
}

/* Tarun's css starts here */


@media only screen and (max-width:600px){
  .UserProfileForm{
      padding: 15px;
      margin-top: 0px;
  }
  .box{
      margin: 0px;
  }
}