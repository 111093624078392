.brand-sec-prt .pagination {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.brand-sec-prt .pagination li a,
.brand-sec-prt .pagination li span {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #000;
  background-color: #fff;
  border: 1px solid #dee2e6;
  text-decoration: none;
}
.brand-sec-prt .pagination li.disabled span,
.brand-sec-prt .pagination li.disabled a {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.brand-sec-prt .pagination li.active span,
.brand-sec-prt .pagination li.active a {
  z-index: 1;
  color: #fff;
  background-color: #8c25f6;
  border-color: #8c25f6;
}
.brand-sec-prt .pagination li:first-child a {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.brand-sec-prt .pagination li:last-child a {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.brand-sec-left {
  padding-left: 50px;
  padding-right: 40px;
}
.brand-sec-left h2 {
  font-weight: bold;
  font-size: 20.2px;
  line-height: 25px;
  letter-spacing: 0.0025em;
  color: #212121;
  margin-bottom: 0;
}
.brand-sec-left .btn-primary {
  background: #8c25f6;
}
.brand-sec-left .btn {
  font-weight: bold;
  border-radius: 36px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.0025em;
  padding: 9px 20px;
}
.brand-sec-left .btn:focus,
.brand-sec-left .btn:active,
.brand-sec-left .btn:active:focus {
  box-shadow: none;
}
.brand-white-bg {
  background: #fff;
  border: 1px solid #e6e5e5;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
  width: 100%;
}
.volume-border,
.cpa-border {
  display: inline-block;
  width: 30px;
  height: 5px;
  border-radius: 8px;
  margin-right: 10px;
  vertical-align: middle;
}
.volume-border {
  background: #8c25f6;
}
.cpa-border {
  background: #9ed229;
}
.brand-white-bg h4 {
  font-weight: bold;
  font-size: 20.2px;
  line-height: 25px;
  letter-spacing: 0.0025em;
  color: #212121;
}
.progress-circle {
  font-size: 20px;
  margin: 20px;
  position: relative;
  padding: 0;
  width: 10em;
  height: 10em;
  background-color: #f2e9e1;
  border-radius: 50%;
  line-height: 5em;
}
.progress-circle span {
  position: absolute;
  line-height: 10em;
  width: 10em;
  text-align: center;
  display: block;
  color: #53777a;
  z-index: 2;
}
.progress-circle.over50 .left-half-clipper {
  clip: rect(auto, auto, auto, auto);
}
.left-half-clipper {
  border-radius: 50%;
  width: 10em;
  height: 10em;
  position: absolute;
  clip: rect(0, 10em, 10em, 5em);
}
.progress-circle.over50 .first50-bar {
  position: absolute;
  clip: rect(0, 10em, 10em, 5em);
  background: linear-gradient(180deg, #8c25f6 0%, #d925f6 100%);
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.value-bar {
  position: absolute;
  clip: rect(0, 5em, 10em, 0);
  width: 10em;
  height: 10em;
  border-radius: 50%;
  border: 0.45em solid #8c25f6;
  background: linear-gradient(180deg, #8c25f6 0%, #d925f6 100%);
}
.progress-circle::after {
  border: none;
  position: absolute;
  top: 0.35em;
  left: 0.35em;
  text-align: center;
  display: block;
  border-radius: 50%;
  width: 9.3em;
  height: 9.3em;
  background-color: white;
  content: " ";
}
.brand-white-bg h5 {
  font-weight: bold;
  font-size: 40px;
  line-height: 49px;
  letter-spacing: 0.0025em;
  margin-bottom: 0;
  color: #000000;
}
.progress-circle:not(.over50) .first50-bar {
  display: none;
}

.brand-sec-right .card p {
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.004em;
  color: #8c25f6;
}
/*Create Brand*/
#regForm .progress {
  background: transparent;
  position: relative;
}
#regForm .progress:after {
  content: "";
  background: #efefef;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  height: 4px;
  top: 3.5px;
  border-radius: 15px;
}
#regForm .progress-bar {
  background: #b4ff00;
  height: 11px;
  z-index: 1;
  border-radius: 19px;
}

.brand-custom-form label {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 30px;
}

.brand-custom-form input:active,
.brand-custom-form input:focus {
  box-shadow: none;
}
.brand-custom-form input[type="text"] {
  background: transparent;
  color: #a9a9a9;
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  border: none;
  padding-left: 0;
  margin: 10px 0px;
}
.brand-custom-form .input-group-text {
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  color: #5f5f5f;
  padding: 0;
  background: transparent;
  border: none;
}
.brand-custom-dropdown .brand-dropdown {
  border: 1px solid #ccd0d2;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #a5a5a5;
  text-align: left;
  padding: 18px 25px;
  height: auto;
  cursor: pointer;
}
.brand-custom-dropdown .brand-dropdown i {
  color: #8c25f6;
}
.brand-custom-dropdown #catefory ul,
.brand-custom-dropdown #optimization ul {
  background: #ffffff;
  border: 1px solid #e0e0e0;
}
.brand-custom-dropdown #catefory ul li,
.brand-custom-dropdown #optimization ul li {
  border-bottom: 1px solid #e0e0e0;
  padding: 0;
}
.brand-custom-dropdown #catefory ul li:active,
.brand-custom-dropdown #optimization ul li:active {
  background: transparent;
}
.brand-custom-dropdown #catefory ul li label,
.brand-custom-dropdown #optimization ul li label {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  margin-bottom: 0;
  padding: 17px 25px;
  width: calc(100% - 2.5rem);
}
.progress-span {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #838383;
}

.brand-custom-dropdown .custom-control-label::after,
.brand-custom-dropdown .custom-control-label::before {
  left: auto;
  right: -1rem;
  width: 23px;
  height: 23px;
  top: 12px;
}
.brand-custom-dropdown .custom-control-label::before {
  background: #ffffff;
  border: 1px solid #ccd0d2;
}
.brand-custom-dropdown
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background: #8c25f6;
  box-shadow: none;
  border-color: #8c25f6;
}
.brand-custom-form i.fa-info-circle {
  color: #8c25f6;
  font-size: 23px;
  margin-left: 10px;
}
.brand-custom-form .span-text {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #a7a7a7;
}
/* For the radio button text */
.brand-custom-form .MuiTypography-body1 { 
  font-weight: 500;
 }
.url-heading {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #5f5f5f;
}
.url-list {
  background: #b4ff00;
  border-radius: 39px;
  padding: 14px 30px;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  display: block;
  margin-bottom: 15px;
}
.url-list:hover {
  text-decoration: none;
}
.url-list.active {
  background: #8c25f6;
  color: #fff !important;
}
.ads-heading {
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: #000000;
}
.ads-p {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.ads-video-sec {
  max-width: 738px;
  min-height: 370px;
  background: #e3e3e3;
  border-radius: 5px;
}
.ads-video-sec p {
  font-weight: bold;
  font-size: 31px;
  line-height: 42px;
  color: #000000;
  padding-top: 150px;
}
.ads-shadow {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  box-shadow: 1px 1px 7px #ccc;
  padding: 20px;
}
.ads-shadow img {
  height: 25px;
}
.ads-shadow span {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-top: 3px;
}
.ads-shadow .btn {
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  padding: 3px 13px;
}
.bg-circle-image {
  background: url("/images/bg_circle.jpg") center no-repeat;
}
.last-step-heading {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

/*Cam objective*/
.object-sec h2 {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.object-sec .brand-white-bg {
  border-radius: 5px;
}
.object-sec .brand-white-bg img {
  max-width: 95px;
  width: auto;
}
.object-sec .brand-white-bg h5 {
  font-weight: bold;
  font-size: 13px;
  line-height: 17px;
  color: #000000;
}
.object-sec .brand-white-bg p {
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #8f8f8f;
}
.object-sec .brand-white-bg {
  position: relative;
}
.object-sec .brand-white-bg .full-sec {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.object-sec .cam-span {
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}
.object-sec .cus-link {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #8c25f6;
}
.object-sec a:hover {
  text-decoration: none;
}
.cus-label-span {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #bdbdbd;
}
.cus-calender .input-group {
  border: 1px solid #ccd0d2;
  border-radius: 4px;
}
.cus-calender .input-group-prepend {
  background: #f9f9f9;
  border-radius: 3px 0 0 3px;
  border: none;
  border-right: 1px solid #ccd0d2;
  margin-right: 0;
}
.cus-calender .input-group-prepend .input-group-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  border: none;
  margin-right: 0;
}
.cus-calender i.input-group-text {
  margin-right: 0;
  border: none;
  color: #bebebe;
  background: #fff;
}
.cus-calender .input-group input,
.cus-calender .input-group input:disabled {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  border: none;
  background: #fff;
}
.cus-calender .input-group input:focus,
.cus-calender .input-group input:active {
  box-shadow: none;
}

#mobilescreen-cover {
  display: none;
}
.statatics h5 {
  padding: 22px 0px;
}
.outer_campaign p {
  word-break: break-all;
}
/**/
.table-responsive-campaigns {
  max-height: 400px;
  overflow: auto;
}
.table-responsive-campaigns .table {
  border-collapse: separate;
  border-spacing: 0 10px;
}
.table-responsive-campaigns .table tr {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}
.table-responsive-campaigns .table td {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  vertical-align: middle;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding: 20px 15px;
}
.table-responsive-campaigns .table td:first-child {
  border-left: 1px solid #dee2e6;
  border-radius: 4px 0 0 4px;
}
.table-responsive-campaigns .table td:last-child {
  border-right: 1px solid #dee2e6;
  border-radius: 0 4px 4px 0;
}
.table-responsive-campaigns .table td p {
  font-weight: bold;
  margin-bottom: 7px;
}
.table-responsive-campaigns .table td span {
  font-weight: 500;
  margin-bottom: 10px;
}
.table-responsive-campaigns .table td p.color-gray {
  color: #a5a5a5;
}
.table-responsive-campaigns .table td p.color-per {
  color: #9ed229;
}
.table-responsive-campaigns .table td p.small {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.004em;
}
.table-responsive-campaigns .table td p.small ~ span {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.004em;
}

.table-responsive-campaigns .table td .custom-control-label::after,
.table-responsive-campaigns .table td .custom-control-label::before {
  width: 23px;
  height: 23px;
  top: 5px;
}
.table-responsive-campaigns .table td .custom-control-label::before {
  background: #ffffff;
  border: 1px solid #ccd0d2;
}
.table-responsive-campaigns
  .table
  td
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background: #8c25f6;
  box-shadow: none;
  border-color: #8c25f6;
}
.btn-brand-next {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border-radius: 50px;
  padding: 10px 50px;
}
/**/
.brand-modal .modal-dialog {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: #ffffff;
}
.brand-modal .modal-body {
  padding: 1.5rem;
}
.brand-modal h4 {
  font-weight: bold;
  font-size: 26.2px;
  line-height: 32px;
  color: #000000;
}
.brand-modal p {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #9c9c9c;
  margin-bottom: 25px;
}
.brand-modal .btn {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  padding: 7px 26px;
}
.brand-modal .popup-color-per {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #8c25f6;
}

.brand-circle-text h5 {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.brand-circle-text p {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
}
.brand-bg-gradiant {
  background: linear-gradient(180deg, #8c25f6 0%, #dc6eee 100%);
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
}
.brand-bg-gradiant p {
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.004em;
  color: #ffffff;
  margin-bottom: 5px;
}

.campaign-progress-circle {
  font-size: 20px;
  margin: 20px;
  position: relative;
  padding: 0;
  width: 10em;
  height: 10em;
  background: transparent;
  border-radius: 50%;
  line-height: 10em;
}
.campaign-progress-circle:after {
  border: none;
  position: absolute;
  top: 0.35em;
  left: 0.35em;
  text-align: center;
  display: block;
  border-radius: 50%;
  width: 9.3em;
  height: 9.3em;
  background: #f9f9f9;
  content: "";
}
.campaign-progress-circle span {
  position: absolute;
  line-height: 3.6em;
  width: 100%;
  text-align: center;
  display: block;
  color: #000;
  z-index: 2;
  font-size: 55px;
  font-weight: bold;
}
.left-half-clipper {
  border-radius: 50%;
  width: 10em;
  height: 10em;
  position: absolute;
  clip: rect(0, 10em, 10em, 5em);
}
.campaign-progress-circle.over50 .left-half-clipper {
  clip: rect(auto, auto, auto, auto);
}
.value-bar {
  position: absolute;
  clip: rect(0, 5em, 10em, 0);
  width: 10em;
  height: 10em;
  border-radius: 50%;
  border: 0.45em solid #8c25f6;
  background: linear-gradient(180deg, #8c25f6 0%, #d925f6 100%);
}
.campaign-progress-circle.over50 .first50-bar {
  position: absolute;
  clip: rect(0, 10em, 10em, 5em);
  background: linear-gradient(180deg, #8c25f6 0%, #d925f6 100%);
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.campaign-progress-circle:not(.over50) .first50-bar {
  display: none;
}
/* Progress bar rotation position */
.campaign-progress-circle.p0 .value-bar {
  display: none;
}
.campaign-progress-circle.p1 .value-bar {
  transform: rotate(4deg);
}
.campaign-progress-circle.p2 .value-bar {
  transform: rotate(7deg);
}
.campaign-progress-circle.p3 .value-bar {
  transform: rotate(11deg);
}
.campaign-progress-circle.p4 .value-bar {
  transform: rotate(14deg);
}
.campaign-progress-circle.p5 .value-bar {
  transform: rotate(18deg);
}
.campaign-progress-circle.p6 .value-bar {
  transform: rotate(22deg);
}
.campaign-progress-circle.p7 .value-bar {
  transform: rotate(25deg);
}
.campaign-progress-circle.p8 .value-bar {
  transform: rotate(29deg);
}
.campaign-progress-circle.p9 .value-bar {
  transform: rotate(32deg);
}
.campaign-progress-circle.p10 .value-bar {
  transform: rotate(36deg);
}
.campaign-progress-circle.p11 .value-bar {
  transform: rotate(40deg);
}
.campaign-progress-circle.p12 .value-bar {
  transform: rotate(43deg);
}
.campaign-progress-circle.p13 .value-bar {
  transform: rotate(47deg);
}
.campaign-progress-circle.p14 .value-bar {
  transform: rotate(50deg);
}
.campaign-progress-circle.p15 .value-bar {
  transform: rotate(54deg);
}
.campaign-progress-circle.p16 .value-bar {
  transform: rotate(58deg);
}
.campaign-progress-circle.p17 .value-bar {
  transform: rotate(61deg);
}
.campaign-progress-circle.p18 .value-bar {
  transform: rotate(65deg);
}
.campaign-progress-circle.p19 .value-bar {
  transform: rotate(68deg);
}
.campaign-progress-circle.p20 .value-bar {
  transform: rotate(72deg);
}
.campaign-progress-circle.p21 .value-bar {
  transform: rotate(76deg);
}
.campaign-progress-circle.p22 .value-bar {
  transform: rotate(79deg);
}
.campaign-progress-circle.p23 .value-bar {
  transform: rotate(83deg);
}
.campaign-progress-circle.p24 .value-bar {
  transform: rotate(86deg);
}
.campaign-progress-circle.p25 .value-bar {
  transform: rotate(90deg);
}
.campaign-progress-circle.p26 .value-bar {
  transform: rotate(94deg);
}
.campaign-progress-circle.p27 .value-bar {
  transform: rotate(97deg);
}
.campaign-progress-circle.p28 .value-bar {
  transform: rotate(101deg);
}
.campaign-progress-circle.p29 .value-bar {
  transform: rotate(104deg);
}
.campaign-progress-circle.p30 .value-bar {
  transform: rotate(108deg);
}
.campaign-progress-circle.p31 .value-bar {
  transform: rotate(112deg);
}
.campaign-progress-circle.p32 .value-bar {
  transform: rotate(115deg);
}
.campaign-progress-circle.p33 .value-bar {
  transform: rotate(119deg);
}
.campaign-progress-circle.p34 .value-bar {
  transform: rotate(122deg);
}
.campaign-progress-circle.p35 .value-bar {
  transform: rotate(126deg);
}
.campaign-progress-circle.p36 .value-bar {
  transform: rotate(130deg);
}
.campaign-progress-circle.p37 .value-bar {
  transform: rotate(133deg);
}
.campaign-progress-circle.p38 .value-bar {
  transform: rotate(137deg);
}
.campaign-progress-circle.p39 .value-bar {
  transform: rotate(140deg);
}
.campaign-progress-circle.p40 .value-bar {
  transform: rotate(144deg);
}
.campaign-progress-circle.p41 .value-bar {
  transform: rotate(148deg);
}
.campaign-progress-circle.p42 .value-bar {
  transform: rotate(151deg);
}
.campaign-progress-circle.p43 .value-bar {
  transform: rotate(155deg);
}
.campaign-progress-circle.p44 .value-bar {
  transform: rotate(158deg);
}
.campaign-progress-circle.p45 .value-bar {
  transform: rotate(162deg);
}
.campaign-progress-circle.p46 .value-bar {
  transform: rotate(166deg);
}
.campaign-progress-circle.p47 .value-bar {
  transform: rotate(169deg);
}
.campaign-progress-circle.p48 .value-bar {
  transform: rotate(173deg);
}
.campaign-progress-circle.p49 .value-bar {
  transform: rotate(176deg);
}
.campaign-progress-circle.p50 .value-bar {
  transform: rotate(180deg);
}
.campaign-progress-circle.p51 .value-bar {
  transform: rotate(184deg);
}
.campaign-progress-circle.p52 .value-bar {
  transform: rotate(187deg);
}
.campaign-progress-circle.p53 .value-bar {
  transform: rotate(191deg);
}
.campaign-progress-circle.p54 .value-bar {
  transform: rotate(194deg);
}
.campaign-progress-circle.p55 .value-bar {
  transform: rotate(198deg);
}
.campaign-progress-circle.p56 .value-bar {
  transform: rotate(202deg);
}
.campaign-progress-circle.p57 .value-bar {
  transform: rotate(205deg);
}
.campaign-progress-circle.p58 .value-bar {
  transform: rotate(209deg);
}
.campaign-progress-circle.p59 .value-bar {
  transform: rotate(212deg);
}
.campaign-progress-circle.p60 .value-bar {
  transform: rotate(216deg);
}
.campaign-progress-circle.p61 .value-bar {
  transform: rotate(220deg);
}
.campaign-progress-circle.p62 .value-bar {
  transform: rotate(223deg);
}
.campaign-progress-circle.p63 .value-bar {
  transform: rotate(227deg);
}
.campaign-progress-circle.p64 .value-bar {
  transform: rotate(230deg);
}
.campaign-progress-circle.p65 .value-bar {
  transform: rotate(234deg);
}
.campaign-progress-circle.p66 .value-bar {
  transform: rotate(238deg);
}
.campaign-progress-circle.p67 .value-bar {
  transform: rotate(241deg);
}
.campaign-progress-circle.p68 .value-bar {
  transform: rotate(245deg);
}
.campaign-progress-circle.p69 .value-bar {
  transform: rotate(248deg);
}
.campaign-progress-circle.p70 .value-bar {
  transform: rotate(252deg);
}
.campaign-progress-circle.p71 .value-bar {
  transform: rotate(256deg);
}
.campaign-progress-circle.p72 .value-bar {
  transform: rotate(259deg);
}
.campaign-progress-circle.p73 .value-bar {
  transform: rotate(263deg);
}
.campaign-progress-circle.p74 .value-bar {
  transform: rotate(266deg);
}
.campaign-progress-circle.p75 .value-bar {
  transform: rotate(270deg);
}
.campaign-progress-circle.p76 .value-bar {
  transform: rotate(274deg);
}
.campaign-progress-circle.p77 .value-bar {
  transform: rotate(277deg);
}
.campaign-progress-circle.p78 .value-bar {
  transform: rotate(281deg);
}
.campaign-progress-circle.p79 .value-bar {
  transform: rotate(284deg);
}
.campaign-progress-circle.p80 .value-bar {
  transform: rotate(288deg);
}
.campaign-progress-circle.p81 .value-bar {
  transform: rotate(292deg);
}
.campaign-progress-circle.p82 .value-bar {
  transform: rotate(295deg);
}
.campaign-progress-circle.p83 .value-bar {
  transform: rotate(299deg);
}
.campaign-progress-circle.p84 .value-bar {
  transform: rotate(302deg);
}
.campaign-progress-circle.p85 .value-bar {
  transform: rotate(306deg);
}
.campaign-progress-circle.p86 .value-bar {
  transform: rotate(310deg);
}
.campaign-progress-circle.p87 .value-bar {
  transform: rotate(313deg);
}
.campaign-progress-circle.p88 .value-bar {
  transform: rotate(317deg);
}
.campaign-progress-circle.p89 .value-bar {
  transform: rotate(320deg);
}
.campaign-progress-circle.p90 .value-bar {
  transform: rotate(324deg);
}
.campaign-progress-circle.p91 .value-bar {
  transform: rotate(328deg);
}
.campaign-progress-circle.p92 .value-bar {
  transform: rotate(331deg);
}
.campaign-progress-circle.p93 .value-bar {
  transform: rotate(335deg);
}
.campaign-progress-circle.p94 .value-bar {
  transform: rotate(338deg);
}
.campaign-progress-circle.p95 .value-bar {
  transform: rotate(342deg);
}
.campaign-progress-circle.p96 .value-bar {
  transform: rotate(346deg);
}
.campaign-progress-circle.p97 .value-bar {
  transform: rotate(349deg);
}
.campaign-progress-circle.p98 .value-bar {
  transform: rotate(353deg);
}
.campaign-progress-circle.p99 .value-bar {
  transform: rotate(356deg);
}
.campaign-progress-circle.p100 .value-bar {
  transform: rotate(360deg);
}
/**/

.bg-circle-loading {
  background: url("/images/bg_circle_2.jpg") no-repeat bottom;
  background-size: contain;
}
.preparing-sec-prt .preparing-top-sec h4 {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.preparing-sec-prt .preparing-top-sec p {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #4e4e4e;
}
.preparing-sec-prt .preparing-top-sec .btn {
  font-weight: bold;
  font-size: 18px;
  line-height: 35px;
  color: #ffffff;
  max-width: 100%;
  width: 394px;
  height: 50px;
  border-radius: 50px;
}
.preparing-sec-prt .progress {
  background: #c4c4c4;
  border-radius: 30px;
  height: 25px;
}
.preparing-sec-prt .progress-bar {
  border-radius: 30px;
  background: linear-gradient(180deg, #b4ff00 0%, #70ff00 100%);
  height: 25px;
}
.preparing-sec-prt .progress .progress-span {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #838383;
}
.preparing-sec-prt .preparing-heading h5 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.preparing-sec-prt .preparing-heading a {
  font-weight: bold;
  font-size: 14px;
  line-height: 29px;
  color: #8c25f6;
}
.preparing-sec-prt .card {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}
.preparing-sec-prt .card .card-img-top {
  border-radius: 8px 8px 0 0;
  width: 100%;
}
.preparing-sec-prt .card .card-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  color: #000;
}
.preparing-sec-prt .card .card-text {
  font-size: 11px;
  font-weight: normal;
  line-height: 15px;
  color: #7e7e7e;
}
.preparing-sec-prt .card .btn {
  font-weight: bold;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.004em;
  color: #ffffff;
  width: 115px;
  text-align: center;
  padding: 8px;
}

@media screen and (max-width: 1050px) {
  .brand-sec-left {
    padding-left: 30px;
    padding-right: 30px;
  }
  .brand-white-bg .btn {
    padding: 8px 15px;
  }
  .brand-sec-right .nav-tabs .nav-link,
  .brand-sec-right .nav-tabs .nav-link.active,
  .brand-sec-right .nav-tabs .nav-item.show .nav-link {
    font-size: 11px;
  }
}
@media screen and (max-width: 994px) {
  .brand-sec-left {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 767px) {
  .outer_campaign .col-md-2.col-sm-4,
  #edit_section {
    border-top: 1px solid #dee2e6;
    padding-top: 19px;
    margin-top: 13px;
  }
}
@media screen and (max-width: 575px) {
  .outer_campaign .col-md-3.col-sm-8 {
    border-top: 1px solid #dee2e6;
    padding-top: 19px;
    margin-top: 13px;
  }
  .brand-sec-left .new_brand_add {
    margin-left: auto;
    margin-top: 16px;
  }
  .brand-custom-form input[type="text"] {
    font-size: 1.4em;
  }
  .brand-custom-form label {
    font-size: 19px;
    margin-bottom: 15px;
  }
  .brand-custom-form .input-group-text {
    font-size: 30px;
  }
  .brand-custom-dropdown .brand-dropdown {
   
    padding: 18px 25px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 14px;
  }
  
}
.active_tab {
  box-shadow: 0px 0px 9px 0.5px #8eff01;
}

#Step2 .error,
#Step5 .error {
  padding-bottom: 0;
}
.next_process {
  transform: rotate(270deg);
}
.previous_process.rotate {
  transform: rotate(90deg);
}
.error {
  border-bottom: 3px solid;
  padding-bottom: 10px;
}
.brand-custom-form .form-control.error {
  border-bottom: 3px solid red;
}
.disabled_traverse {
  background: #5a7d04;
}
.navination_bar {
  /* position: relative;  commented by prashant*/
  margin-bottom: 15px; /*bottom: 4%; right: 10%;*/
  margin-left:auto; 
}
.above_navigation,
.below_navigation {
  background: #b4ff00;
  margin-right: 10px;
  border-radius: 4px;
}
.above_navigation_box,
.below_navigation_box {
  
  margin-right: 10px;
  border-radius: 4px;
}
#facebook_adds {
  max-height: 350px;
  overflow-y: scroll;
}
.group-horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.button.connect-saved-btn {
  margin-bottom: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #7a7a7a;
  background-color: #fff;
  color: #000;
  font-size: 12px;
}
.finiteHeight {
  height: 300px;
}
.button {
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  padding: 10px 24px;
  border-radius: 50px;
  background-color: #8d02fa;
  /* font-family: Montserrat, sans-serif; */
  color: #fff;
  text-align: center;
}
.w-button {
  display: inline-block;
  padding: 9px 15px;
  background-color: #3898ec;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}
.icon-small {
  margin-right: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
h4.connect-acc-label {
  margin-top: 4px;
  margin-bottom: 4px;
  /* font-family: Montserrat, sans-serif; */
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}
.tab-content > .tab-pane {
  min-height: 100vh;
}
/* input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
} */
.connect-acc-list.title-label {
  background-color: #f2f2f2;
  font-weight: 700;
}

.toggle {
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 20px;
  background: grey;
  display: block;
  border-color: #e5e5e5;
  border-radius: 100px;
  position: relative;
}

.toggle:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  border-color: #e5e5e5;
  background: #fff;
  border-radius: 90px;
  transition: 0.5s;
}

input:checked + .toggle {
  background: #b4ff00;
}

input:checked + .toggle:after {
  left: calc(100% - 0px);
  transform: translateX(-100%);
}

.toggle:active:after {
  width: 10px;
}
.connect-acc-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}
.acc-id {
  width: 75px;
  margin-right: 10px;
  font-size: 12px;
}
.acc-toggle {
  margin-top: -15px;
}
.div-block-73 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.object-sec .active_option .brand-white-bg {
  box-shadow: 0px 0px 5px 1px #8d02fa;
}
.cus-calender .input-group input,
.cus-calender .input-group input:disabled {
  padding: 10px;
}
.SingleDatePickerInput__withBorder {
  border: none !important;
}
.DateInput.DateInput_1 {
  width: 100%;
}
.active_tab_link .brand-white-bg {
  box-shadow: 0px 0px 9px 0.5px #8eff01;
}
#step6 .brand-custom-dropdown .brand-dropdown.blank_li {
  background: rgba(237, 237, 237, 1);
  background: -moz-linear-gradient(
    left,
    rgba(237, 237, 237, 1) 0%,
    rgba(254, 254, 254, 1) 27%,
    rgba(235, 235, 235, 1) 59%,
    rgba(254, 254, 254, 1) 98%,
    rgba(254, 254, 254, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(237, 237, 237, 1)),
    color-stop(27%, rgba(254, 254, 254, 1)),
    color-stop(59%, rgba(235, 235, 235, 1)),
    color-stop(98%, rgba(254, 254, 254, 1)),
    color-stop(100%, rgba(254, 254, 254, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(237, 237, 237, 1) 0%,
    rgba(254, 254, 254, 1) 27%,
    rgba(235, 235, 235, 1) 59%,
    rgba(254, 254, 254, 1) 98%,
    rgba(254, 254, 254, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(237, 237, 237, 1) 0%,
    rgba(254, 254, 254, 1) 27%,
    rgba(235, 235, 235, 1) 59%,
    rgba(254, 254, 254, 1) 98%,
    rgba(254, 254, 254, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(237, 237, 237, 1) 0%,
    rgba(254, 254, 254, 1) 27%,
    rgba(235, 235, 235, 1) 59%,
    rgba(254, 254, 254, 1) 98%,
    rgba(254, 254, 254, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(237, 237, 237, 1) 0%,
    rgba(254, 254, 254, 1) 27%,
    rgba(235, 235, 235, 1) 59%,
    rgba(254, 254, 254, 1) 98%,
    rgba(254, 254, 254, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#fefefe', GradientType=1 );
  color: #d5d5d5;
  background-size: 400% 400%;
  -webkit-animation: AnimationName 2s ease infinite;
  -moz-animation: AnimationName 2s ease infinite;
  -o-animation: AnimationName 2s ease infinite;
  animation: AnimationName 2s ease infinite;
}

.grey_bg {
  background-size: 400% 400%;
  -webkit-animation: AnimationName 2s ease infinite;
  -moz-animation: AnimationName 2s ease infinite;
  -o-animation: AnimationName 2s ease infinite;
  animation: AnimationName 2s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 7% 0%;
  }
  50% {
    background-position: 94% 100%;
  }
  100% {
    background-position: 7% 0%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 7% 0%;
  }
  50% {
    background-position: 94% 100%;
  }
  100% {
    background-position: 7% 0%;
  }
}
@-o-keyframes AnimationName {
  0% {
    background-position: 7% 0%;
  }
  50% {
    background-position: 94% 100%;
  }
  100% {
    background-position: 7% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 7% 0%;
  }
  50% {
    background-position: 94% 100%;
  }
  100% {
    background-position: 7% 0%;
  }
}
#catefory ul li .custom-control-label {
  text-transform: capitalize !important;
}
.collapse .list-unstyled {
  max-height: 350px;
  overflow: auto;
}
.grey_bg.loading {
  background: #e8e5e591;
  padding: 6px;
  color: #e8e5e591;
  margin-bottom: 12px;
}
.animated {
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}
.list_event_list ul {
  padding: 0;
}
.MainClassLi {
  background: #c5c5c5;
  padding: 15px 15px !important;
  display: block;
  pointer-events: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  margin-bottom: 0;
}
.brand-sec-prt .pagination {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.brand-sec-prt .pagination li a,
.brand-sec-prt .pagination li span {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #000;
  background-color: #fff;
  border: 1px solid #dee2e6;
  text-decoration: none;
}
.brand-sec-prt .pagination li.disabled span,
.brand-sec-prt .pagination li.disabled a {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.brand-sec-prt .pagination li.active span,
.brand-sec-prt .pagination li.active a {
  z-index: 1;
  color: #fff;
  background-color: #8c25f6;
  border-color: #8c25f6;
}
.brand-sec-prt .pagination li:first-child a {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.brand-sec-prt .pagination li:last-child a {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.brand-sec-left {
  padding-left: 50px;
  padding-right: 40px;
}
.brand-sec-left h2 {
  font-weight: bold;
  font-size: 20.2px;
  line-height: 25px;
  letter-spacing: 0.0025em;
  color: #212121;
  margin-bottom: 0;
}
.brand-sec-left .btn-primary {
  background: #8c25f6;
}
.brand-sec-left .btn {
  font-weight: bold;
  border-radius: 36px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.0025em;
  padding: 9px 20px;
}
.brand-sec-left .btn:focus,
.brand-sec-left .btn:active,
.brand-sec-left .btn:active:focus {
  box-shadow: none;
}
.brand-white-bg {
  background: #fff;
  border: 1px solid #e6e5e5;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
  width: 100%;
}
.volume-border,
.cpa-border {
  display: inline-block;
  width: 30px;
  height: 5px;
  border-radius: 8px;
  margin-right: 10px;
  vertical-align: middle;
}
.volume-border {
  background: #8c25f6;
}
.cpa-border {
  background: #9ed229;
}
.brand-white-bg h4 {
  font-weight: bold;
  font-size: 20.2px;
  line-height: 25px;
  letter-spacing: 0.0025em;
  color: #212121;
}
.brand-white-bg h5 {
  font-weight: bold;
  font-size: 40px;
  line-height: 49px;
  letter-spacing: 0.0025em;
  margin-bottom: 0;
  color: #000000;
}
.brand-white-bg h5 sup {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  top: -1.4em;
}
.brand-white-bg .btn {
  padding: 8px 24px;
  font-size: 11px;
  font-weight: 600;
}
.brand-white-bg p.small {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #a4a2a2;
  letter-spacing: 0.0025em;
}
.brand-white-bg li.small {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #737373;
}
.brand-purple-bg {
  background: #8c25f6;
  box-shadow: 1px 1px 7px #ccc;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 380px;
}
.brand-plus {
  font-size: 125px;
  font-weight: 900;
  color: #fff;
  line-height: 1;
}
.brand-plus-txt {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
}
.brand-purple-bg:hover {
  text-decoration: none;
}
.brand-sec-right {
  background-image: linear-gradient(180deg, #8d02fa 0%, #dc6eee 100%);
  position: fixed;
  height: 100vh;
  right: 0;
  top: 0;
  z-index: -1;
}
.brand-sec-right h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.005em;
  color: #fff;
  margin-top: 30px;
  margin-bottom: 20px;
}
.brand-sec-right .nav-tabs .nav-item {
  display: inline-block;
  width: 25%;
  text-align: center;
}
.brand-sec-right .nav-tabs .nav-link,
.brand-sec-right .nav-tabs .nav-link.active,
.brand-sec-right .nav-tabs .nav-item.show .nav-link {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
  padding: 7px 3px;
  background: none;
  border: none;
}
.brand-sec-right .nav-tabs {
  border-bottom: 1px solid #b1b1b1;
}
.brand-sec-right .nav-tabs .nav-link.active,
.brand-sec-right .nav-tabs .nav-item.show .nav-link {
  border-bottom: 3px solid #b4ff00;
}
.brand-sec-right h4 {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  margin-top: 20px;
}
.brand-sec-right h5 {
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.004em;
  color: #000000;
}
.brand-sec-right .card p {
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.004em;
  color: #8c25f6;
}
/*Create Brand*/
#regForm .progress {
  background: transparent;
  position: relative;
}
#regForm .progress:after {
  content: "";
  background: #efefef;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  height: 4px;
  top: 3.5px;
  border-radius: 15px;
}
#regForm .progress-bar {
  background: #b4ff00;
  height: 11px;
  z-index: 1;
  border-radius: 19px;
}
/* .brand-custom-form label {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 30px;
} */
.brand-custom-form input:active,
.brand-custom-form input:focus {
  box-shadow: none;
}
.brand-custom-form input[type="text"].custom-target-budget-input{
  background: transparent;
  color: #a9a9a9;
  font-weight: bold;
  font-size: 24px;
  line-height: 46px;
  border: 1px solid #ccd0d2;
  border-radius: 5px;
  padding-left: 10px;
  margin: 0px 15px;
  outline: none;
}
.custom-target-budget-input::placeholder{
  color: #b1b7ba;
}
.brand-custom-form input[type="text"] {
  background: transparent;
  color: #a9a9a9;
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  border: none;
  padding-left: 0;
  min-height: 45px;
}
.brand-custom-form .input-group-text {
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  color: #5f5f5f;
  padding: 0;
  background: transparent;
  border: none;
}

/* .brand-custom-dropdown .brand-dropdown {
  border: 1px solid #ccd0d2;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #a5a5a5;
  text-align: left;
  padding: 18px 25px;
  height: auto;
  cursor: pointer;
} */
.brand-custom-dropdown .brand-dropdown i {
  color: #8c25f6;
}
.brand-custom-dropdown #catefory ul,
.brand-custom-dropdown #optimization ul {
  background: #ffffff;
  border: 1px solid #e0e0e0;
}
.brand-custom-dropdown #catefory ul li,
.brand-custom-dropdown #optimization ul li {
  border-bottom: 1px solid #e0e0e0;
  padding: 0;
}
.brand-custom-dropdown #catefory ul li:active,
.brand-custom-dropdown #optimization ul li:active {
  background: transparent;
}
.brand-custom-dropdown #catefory ul li label,
.brand-custom-dropdown #optimization ul li label {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  margin-bottom: 0;
  padding: 17px 25px;
  width: calc(100% - 2.5rem);
}
.progress-span {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #838383;
}

.brand-custom-dropdown .custom-control-label::after,
.brand-custom-dropdown .custom-control-label::before {
  left: auto;
  right: -1rem;
  width: 23px;
  height: 23px;
  top: 12px;
}
.brand-custom-dropdown .custom-control-label::before {
  background: #ffffff;
  border: 1px solid #ccd0d2;
}
.brand-custom-dropdown
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background: #8c25f6;
  box-shadow: none;
  border-color: #8c25f6;
}
.brand-custom-form i.fa-info-circle {
  color: #8c25f6;
  font-size: 23px;
  margin-left: 10px;
}
.brand-custom-form .span-text {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #a7a7a7;
}
.url-heading {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #5f5f5f;
}
.url-list {
  background: #b4ff00;
  border-radius: 39px;
  padding: 14px 30px;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  display: block;
  margin-bottom: 15px;
}
.url-list:hover {
  text-decoration: none;
}
.url-list.active {
  background: #8c25f6;
  color: #fff !important;
}
.ads-heading {
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: #000000;
}
.ads-p {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.ads-video-sec {
  max-width: 738px;
  min-height: 370px;
  background: #e3e3e3;
  border-radius: 5px;
}
.ads-video-sec p {
  font-weight: bold;
  font-size: 31px;
  line-height: 42px;
  color: #000000;
  padding-top: 150px;
}
.ads-shadow {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  box-shadow: 1px 1px 7px #ccc;
  padding: 20px;
}
.ads-shadow img {
  height: 25px;
}
.ads-shadow span {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-top: 3px;
}
.ads-shadow .btn {
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  padding: 3px 13px;
}
.bg-circle-image {
  background: url("/images/bg_circle.jpg") center no-repeat;
}
.last-step-heading {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

/*Cam objective*/
.object-sec h2 {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.object-sec .brand-white-bg {
  border-radius: 5px;
}
.object-sec .brand-white-bg img {
  max-width: 95px;
  width: auto;
}
.object-sec .brand-white-bg h5 {
  font-weight: bold;
  font-size: 13px;
  line-height: 17px;
  color: #000000;
}
.object-sec .brand-white-bg p {
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #8f8f8f;
}
.object-sec .brand-white-bg {
  position: relative;
}
.object-sec .brand-white-bg .full-sec {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.object-sec .cam-span {
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}
.object-sec .cus-link {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #8c25f6;
}
.object-sec a:hover {
  text-decoration: none;
}
.cus-label-span {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #bdbdbd;
}
.cus-calender .input-group {
  border: 1px solid #ccd0d2;
  border-radius: 4px;
}
.cus-calender .input-group-prepend {
  background: #f9f9f9;
  border-radius: 3px 0 0 3px;
  border: none;
  border-right: 1px solid #ccd0d2;
  margin-right: 0;
}
.cus-calender .input-group-prepend .input-group-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  border: none;
  margin-right: 0;
}
.cus-calender i.input-group-text {
  margin-right: 0;
  border: none;
  color: #bebebe;
  background: #fff;
}
.cus-calender .input-group input,
.cus-calender .input-group input:disabled {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  border: none;
  background: #fff;
}
.cus-calender .input-group input:focus,
.cus-calender .input-group input:active {
  box-shadow: none;
}

#mobilescreen-cover {
  display: none;
}
.statatics h5 {
  padding: 22px 0px;
}
.outer_campaign p {
  word-break: break-all;
}
@media screen and (max-width: 767px) {
  .outer_campaign .col-md-2.col-sm-4,
  #edit_section {
    border-top: 1px solid #dee2e6;
    padding-top: 19px;
    margin-top: 13px;
  }
}
@media screen and (max-width: 994px) {
  .brand-sec-left {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media screen and (max-width: 575px) {
  .outer_campaign .col-md-3.col-sm-8 {
    border-top: 1px solid #dee2e6;
    padding-top: 19px;
    margin-top: 13px;
  }
  .brand-sec-left .new_brand_add {
    margin-left: auto;
    margin-top: 16px;
  }
  .brand-custom-form input[type="text"] {
    font-size: 1.6em;
  }
}
.suggesstion_list_location.active_opened .list_event_list {
  border: 1px solid #e6e6e6;
}
.suggesstion_list_location.active_opened .list_event_list li {
  padding: 10px;
  border-bottom: 1px solid #e6e6e6;
}
.suggesstion_list_location.active_opened .list_event_list li:hover {
  background: #e6e6e6;
  cursor: pointer;
}
.suggesstion_list_location.active_opened {
  margin-top: 10px;
}
.suggesstion_list_location.active_opened .list_event_list {
  padding: 0;
  list-style-type: none;
}
.selected_location_wrapper ul {
  padding: 0;
  margin-top: 10px;
  list-style: none;
}
.selected_location_wrapper ul li {
  background: #b4ff00;
  display: inline-block;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 6px;
}
.selected_location_wrapper ul li > span > i {
  margin-left: 5px;
  margin-right: 0;
  cursor: pointer;
}
.cus-calender .input-group {
  flex-wrap: nowrap;
}

.loader {
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}


.loader .spinner-border{
  width: 100px;
  height: 100px;
  color: #b0adad;
}

.small-loader {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
}

.small-loader .lds-ring {
  width: 30px;
  height: 30px;
}

.small-loader .lds-ring div {
  margin: 0;
  width: 30px;
  height: 30px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #bbb transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.infiniteLoaderWrapper {
  display: flex;
  justify-content: center;
}

.check-icon.MuiSvgIcon-root{
  font-size: 150px;
  color:#4ef34e ;
}

.popup-icon .MuiSvgIcon-root{
 
    font-size: 120px;
    color: #8C25F6;
    text-align: center;
    display: block;
    margin: auto;
    margin-top: 20px;
 
}



.button-card-container{
  height: 100%;
}
.button-card{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid #D9D5EC;
  box-shadow: 0px 2px 8px rgb(155 155 155 / 25%);
  border-radius: 8px;
  text-align: center;
  align-items: center;
  height: 100%;
}
.button-card:focus,.button-card-focused{
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 8px;
  background-color: white;
  padding: 16px;
  outline: none;
  box-shadow: 0px 0px 5px 1px #8d02fa;
  height: 100%;
}

.button-card-heading{
  font-size: 1.2rem;
  font-weight: 700;
}

.button-card-description{
  font-size: .8rem;
  letter-spacing: .3px;
  font-weight: 500;
  color: #929191;
}

button:focus{
  outline:none;
}

.flex-div{
  display: flex;
  align-items: center;
  justify-content: center;
}

.postboost-switchcampaign-container{
  width: 90%;
  margin-left: 5%;
 margin-bottom: 10px;
 margin-top: 10px;
 flex-direction: column;
}

.full-width-button{
  display: block;
  margin-left: 0px !important;
  margin: 0px auto;
  width: 100%;
  margin-bottom: 10px;
}

.campaign-top-bar{
  background-color: #8c25f6;
  padding: 10px 0px;
  font-size: 14px;
}
.slick-dots li button:before {
  color: #B4FF00;
}

.campaign-bold-white{
  color: white;
  font-weight: 600;
}

.roboto-100{
  font-family: 'Roboto', sans-serif;
}
.roboto-300{
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  
}
.roboto-400{
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  letter-spacing: .3px;
  word-spacing: 2px;
}
.roboto-400{
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
.roboto-700{
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}
.roboto-900{
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
}

.bg-F4F2FF{
  background-color: #F4F2FF;
}

.min-spent{
  /* margin-left: 38px; */
}

.border-rounded{
  border-radius: 0.25rem ;
  border: 1px solid #dee2e6 ;
}





@media only screen and (max-width: 600px){
  .campaign-create .brand-custom-form {
    margin-top: 10px;
  }
  .border-rounded{
    border-radius: none;
    border: none;
    height: 45vh;
    overflow-y: auto;
    box-sizing: border-box;
    overflow-x: hidden;
  }
  
  .contentWrapper .mt-5{
    margin-top: 10px !important;
  }
  .SingleDatePicker_picker{
    top: 52px !important;
    left: -121px !important;
  }
  .post-boost-screen .SingleDatePicker_picker {
    top: 62px !important;
    left: 0px !important;
    z-index: 120;
}
  .brand-custom-form input[type="text"].custom-target-budget-input{
    width: 150px;
    padding: 2px;
    font-size: 14px;
    height: 36px !important;
  }

  .brand-custom-form input[type="text"] {
    min-height: 36px;
  }
  .custom-flex{
    flex-direction: column;
  }

  .campaign-top-bar{
    margin-top: 20px !important;
    border-radius: 8px;
    border: 2px solid #B4FF00;
  }
}

