.selectaudiences__container {
  width: 90%;
  margin-left: 5%;
  margin-top: 50px;
  margin-bottom: 66px;
}

.selectaudiences__heading--container {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.selectaudiences__span--name {
  position: relative;
  left: -2%;
}

.selectaudiences__span--description {
  position: relative;
  left: -2.5%;
  word-wrap: break-word;
}

.selectaudiences__span--budget {
  position: relative;
  left: -2.5%;
}

.selectaudiences__span--channel {
  position: relative;
  left: -2%;
}

.selectaudiences__span--population {
  position: relative;
  left: 5%;
}

.selectaudiences__span--cpa {
  position: relative;
  left: 1.5%;
}


@media only screen and (max-width: 900px) {
  .selectaudiences__heading--container {
    font-size: 12px;
  }
}

@media only screen and (max-width: 650px) {
  .selectaudiences__heading--container {
    font-size: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .selectaudiences__heading--container {
    font-size: 8px;
  }
}

@media only screen and (max-width: 400px) {
  .selectaudiences__heading--container {
    font-size: 6px;
  }
}
@media only screen and (max-width: 600px) {
  .selectaudiences__container{
    width: 100%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
  }
}

