.lowerinfosection__container {
  display: block;
  position: relative;
  width: 100%;
  /* padding-top: 20px; */
}

/* .lowerinfosection__container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.15);
  top: 0px;
} */


.lowerinfosection__span--warning {
  color: #F7B202;
  font-weight: 500;
  font-size: 14px;
}

.lowerinfosection__span--error {
  color: rgb(247, 2, 43);
  font-weight: 500;
  font-size: 14px;
}

.lowerinfosection__circle--exclamation {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  background-color: #FFC700;
  margin-right: 10px;
  color: #000;
}