.imagecard__container {
  width: 25rem;

  max-width: 25rem;
  border-radius: 6px;
  box-shadow: 0px, 2px rgba(0, 0, 0, 0.15);
  border: 1px solid #dee2e6;
  position: relative;
  margin-bottom: 4vh;
  background-color: #fff;
  margin-left: 2%;
  margin-top: 20px;
  /* max-height: 47rem; */
}

.imagecard__photo--container {
  position: relative;
}

.imagecard__layover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(41, 41, 41, 0.4);
  visibility: hidden;
  border-radius: 8px;
}

.imagecard__btn--edit {
  width: 46%;
  height: 34px;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 43%;
  transform: translateX(-50%) translateY(-50%);
  font-weight: 600;
  font-size: 14px;
  color: #8c25f6;
  background: #ffffff;
  border: 2px solid #8c25f6;
  border-radius: 17px;
  visibility: hidden;
  cursor: pointer;
}

.imagecard__photo--container:hover .imagecard__layover {
  visibility: visible;
}

.imagecard__photo--container:hover .imagecard__btn--edit {
  visibility: visible;
}

.imagecard__btn--cta--container {
  display: flex;
  justify-content: flex-end;
  margin-right: 4%;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}


.imagecard__btn--cta {
  /* width: 100%; */
  height: 2rem;
  font-weight: 600;
  font-size: 0.9rem;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 3px;
  cursor: pointer;
}

.imagecard__btn--cta:focus,
.imagecard__btn--cta:active {
  outline: none;
}

.imagecard__btn--cta:hover {
  background-color: #000;
  color: #fff;
}

.imagecard__ctaoptions--container {
  padding: 0;
  font-size: 12px;
  width: 5.875rem;
  text-align: center;
  position: absolute;
  right: 0;
  top: 31px;
  z-index: 10;
  /* height: 50px; */
  overflow-y: auto;
  box-shadow: 0px 14px 100px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  background-color: #fff;
  color: #000;
}

.imagecard__ctaoptions--container span {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.imagecard__ctaoptions--container span:hover {
  background-color: #000;
  color: #fff;
  cursor: pointer;
}

.imagecard__ctaoptions--container::-webkit-scrollbar {
  width: 2px;
}

.imagecard__ctaoptions--container::-webkit-scrollbar-thumb {
  background: #8d02fa;
}

.imagecard__heading--container {
  margin-left: 4%;
  margin-top: 1%;
}

.imagecard__heading--bold {
  display: block;
  margin-top: 10px;
  font-weight: bold;
  font-size: 12px;
}

.imagecard__issponsored {
  font-size: 12px;
  line-height: 14.63px;
  color: #9c9c9c;
}

.imagecard__subheading {
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5%;
  position: relative;
  font-weight: 500;
  font-size: 16px;
}

.imagecard__subheading--image-card {
  /* position: absolute; */
  /* top: 1rem; */
  left: 0;
  font-size: 1.4rem;
  font-weight: bold;
}

.imagecard__btn--change-post {
  float: right;
  cursor: pointer;
}

.imagecard__audience--selector {
  width: 90%;
  margin-left: 5%;
  margin-top: 5px;
}

.imagecard__btn--send-message {
  font-size: 13px;
  width: 50%;
  margin-left: 5%;
  float: right;
  margin-right: 5%;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 5px;
}

.imagecard__url--input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 5px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  width: 100%;
}

.imagecard__likes--container {
  border-top: 1px solid #dee2e6;
  font-weight: bold;
  font-size: 12px;
  color: #9c9c9c;
  padding: 5px 5%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
}

.imagecard__url {
  font-weight: 500;
  font-size: 14px;
  /* margin-top: 20px; */
  margin-top: 10px !important;

  /* display: inline-block; */
  margin-left: 5%;
  text-decoration: underline;
}

.imagecard__url--image-card {
  /* position: absolute; */
  top: 0;
  left: 0;
  text-transform: uppercase;
}

.imagecard__img--like {
  width: 30px;
  height: 30px;
}

.imagecard__input--headline {
  padding-bottom: 0px !important;
  border: none;
  /* border-bottom: 1px solid #000; */
  font-weight: 500;
  width: 90%;
}

.imagecard__input--headline:active,
.imagecard__input--headline:focus {
  outline: none;
  border-bottom: 1px solid #000;
}

.imagecard__span--message {
  display: block;
  margin-left: 5%;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* ------- wasim css changes --------*/

.imagecard__photo--container {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.imagecard__photo--container img {
  width: 100%;
  max-width: 100%;
  height: 300px !important;
  min-height: 300px !important;
}

@media only screen and (max-width: 1000px) {
  .imagecard__container {
    width: 40%;
  }
}

@media only screen and (max-width: 600px) {
  .imagecard__container {
    width: 100%;
    margin-left: 0px;
  }
}
