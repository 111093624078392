.rule-builder {
  padding-top: 40px;
  .query-builder {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    .rules-group-container {
      padding: 10px;
      padding-bottom: 6px;
      border: 1px solid #272d4e;
      background: rgb(232, 244, 253);
      background-color: rgba(141, 2, 250, 0.05);
      // color: rgb(141, 2, 250);
      padding: 15px;
      width: 100%;
    }
    .rule-container {
      padding: 15px;
    }
    .rule-placeholder {
      padding: 15px;
    }
    .hide {
      display: none;
    }
    .pull-right {
      float: right !important;
    }
    .btn {
      text-transform: none;
      display: inline-block;
      padding: 6px 12px;
      margin-bottom: 0px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.42857;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      user-select: none;
      background-image: none;
      border: 1px solid transparent;
      border-radius: 4px;
      &:focus {
        color: #333;
        text-decoration: none;
      }
      &:hover {
        color: #333;
        text-decoration: none;
      }
      &:active {
        background-image: none;
        outline: 0px none;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.125) inset;
      }
    }
    .btn.focus {
      color: #333;
      text-decoration: none;
    }
    .btn.active {
      background-image: none;
      outline: 0px none;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.125) inset;
    }
    .btn-success {
      color: #e83e8c;
      background-color: #fff;
      border-color: #e83e8c;
      font-size: 18px;
      &:active {
        color: #fff;
        background-color: #e83e8c;
        border-color: #e83e8c;
      }
      &:focus {
        color: #fff;
        background-color: #e83e8c;
        border-color: #e83e8c;
      }
      &:hover {
        color: #fff;
        background-color: #e83e8c;
        border-color: #e83e8c;
      }
    }
    .btn-primary {
      color: #8d02fa;
      background-color: #fff;
      border-color: #8d02fa;
      &:active {
        color: #fff;
        background-color: #8d02fa;
        border-color: #8d02fa;
      }
      &:focus {
        color: #fff;
        background-color: #8d02fa;
        border-color: #8d02fa;
      }
      &:hover {
        color: #fff;
        background-color: #8d02fa;
        border-color: #8d02fa;
      }
    }
    .btn-danger {
      color: #fff;
      background-color: #d9534f;
      border-color: #d43f3a;
      &:active {
        color: #fff;
        background-color: #c9302c;
        border-color: #ac2925;
      }
      &:focus {
        color: #fff;
        background-color: #c9302c;
        border-color: #ac2925;
      }
      &:hover {
        color: #fff;
        background-color: #c9302c;
        border-color: #ac2925;
      }
    }
    .btn-success.active {
      color: #fff;
      background-color: #e83e8c;
      border-color: #e83e8c;
    }
    .btn-success.focus {
      color: #fff;
      background-color: #286090;
      border-color: #204d74;
    }
    .btn-primary.active {
      color: #fff;
      background-color: #8d02fa;
      border-color: #8d02fa;
    }
    .btn-primary.focus {
      color: #fff;
      background-color: #286090;
      border-color: #204d74;
    }
    .btn-danger.active {
      color: #fff;
      background-color: #c9302c;
      border-color: #ac2925;
    }
    .btn-danger.focus {
      color: #fff;
      background-color: #c9302c;
      border-color: #ac2925;
    }
    .btn-group {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      > .btn {
        position: relative;
        float: left;
        &:first-child {
          margin-left: 0px;
          &:not(:last-child) {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
          }
        }
        &:last-child {
          &:not(:first-child) {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
          }
        }
      }
      .btn {
        + {
          .btn {
            margin-left: -1px;
          }
          .btn-group {
            margin-left: -1px;
          }
        }
      }
      .btn-group {
        + {
          .btn {
            margin-left: -1px;
          }
          .btn-group {
            margin-left: -1px;
          }
        }
      }
    }
    .btn-xs {
      padding: 1px 5px;
      font-size: 18px;
      border-radius: 3px;
    }
    .btn-group-xs {
      > .btn {
        padding: 1px 5px;
        font-size: 18px;
        border-radius: 3px;
      }
    }
  }
  .form-control {
    height: 30px;
  }
}
