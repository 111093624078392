.iframe-container{
  width: 50%;
  margin: auto;
}
.container1 {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; 
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width:1000px){
  .iframe-container{
    width: 50%;
    margin: auto;
  }
}
@media (max-width:800px ) and ( min-width:600px){
  .iframe-container{
    width: 70%;
    margin: auto;
  }
}
@media (max-width:600px){
  .iframe-container{
    width: 90%;
    margin: auto;
  }
}