.videocard__container {
  width: 25rem;
  max-width: 25rem;
  border-radius: 6px;
  box-shadow: 0px, 2px rgba(0, 0, 0, 0.15);
  border: 1px solid #dee2e6;
  position: relative;
  margin-bottom: 4vh;
  background-color: #fff;
  margin-left: 2%;
  margin-top: 20px;
  /* max-height: 47rem; */
}

.videocard__layover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: rgba(41, 41, 41, 0.4);
  visibility: hidden;
  border-radius: 8px;
}

img.videocard__button--play {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.videocard__btn--edit {
  width: 46%;
  height: 34px;
  position: absolute;
  z-index: 51;
  left: 50%;
  top: 43%;
  transform: translateX(-50%) translateY(-50%);
  font-weight: 600;
  font-size: 14px;
  color: #8c25f6;
  background: #ffffff;
  border: 2px solid #8c25f6;
  border-radius: 17px;
  visibility: hidden;
  cursor: pointer;
}

.videocard__photo--container:hover .videocard__layover {
  visibility: visible;
}

.videocard__photo--container:hover .videocard__btn--edit {
  visibility: visible;
}

.videocard__btn--cta--container {
  /* float: right; */
  display: flex;
  justify-content: flex-end;
  margin-right: 4%;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}

.videocard__btn--cta {
  /* width: ; */
  height: 2rem;
  font-weight: 600;
  font-size: 0.9rem;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 3px;
  cursor: pointer;
}

.videocard__btn--cta:focus,
.videocard__btn--cta:active {
  outline: none;
}

.videocard__btn--cta:hover {
  background-color: #000;
  color: #fff;
}

.videocard__ctaoptions--container {
  padding: 0px;
  font-size: 10px;
  width: 107px;
  text-align: center;
  /* position: absolute; */
  left: 0;
  top: 24px;
  z-index: 10;
  height: 50px;
  overflow-y: auto;
  box-shadow: 0px 14px 100px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  background-color: #fff;
  color: #000;
}

.videocard__ctaoptions--container span {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.videocard__ctaoptions--container span:hover {
  background-color: #000;
  color: #fff;
  cursor: pointer;
}

.videocard__ctaoptions--container::-webkit-scrollbar {
  width: 2px;
}

.videocard__ctaoptions--container::-webkit-scrollbar-thumb {
  background: #8d02fa;
}

.videocard__heading--container {
  margin-left: 4%;
  margin-top: 1%;
}

.videocard__heading--bold {
  display: block;
  margin-top: 10px;
  font-weight: bold;
  font-size: 12px;
}

.videocard__issponsored {
  font-size: 12px;
  line-height: 14.63px;
  color: #9c9c9c;
}

.videocard__subheading {
  display: block;
  margin-top: 20px;
  margin-left: 5%;
  position: relative;
  font-weight: 500;
  font-size: 16px;
}

.videocard__btn--change-post {
  float: right;
  cursor: pointer;
}

.videocard__audience--selector {
  width: 90%;
  margin-left: 5%;
  margin-top: 5px;
}

.videocard__btn--send-message {
  font-size: 13px;
  width: 50%;
  margin-left: 5%;
  float: right;
  margin-right: 5%;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 5px;
}

.videocard__likes--container {
  border-top: 1px solid #dee2e6;
  font-weight: bold;
  font-size: 12px;
  color: #9c9c9c;
  padding: 5px 5%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
}

.videocard__url {
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
  /* display: inline-block; */
  margin-left: 5%;
  text-decoration: underline;
}

.videocard__img--like {
  width: 30px;
  height: 30px;
}

.videocard__input--headline {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: none;
  font-weight: 500;
  width: 90%;
}

.videocard__input--headline:active,
.videocard__input--headline:focus {
  outline: none;
  border-bottom: 1px solid #000;
}

.videocard__span--message {
  display: block;
  margin-left: 5%;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* ------- wasim css changes --------*/

.videocard__photo--container {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.react-thumbnail-generator {
  width: 100%;
  max-width: 100%;
  height: 100%;
}
.react-thumbnail-generator img {
  width: 100%;
  max-width: 100%;
  height: 300px !important;
  min-height: 300px !important;
}

.videocard__url--image-card {
  /* position: absolute; */
  /* top: 0;
  left: 0; */
  /* margin-top: 20px; */
  text-transform: uppercase;
}
.videocard__subheading--image-card {
  /* position: absolute;.. */
  top: 1rem;
  left: 0;
  font-size: 1.4rem;
  font-weight: bold;
}
@media only screen and (max-width: 1000px) {
  .videocard__container {
    width: 40%;
  }
}

@media only screen and (max-width: 600px) {
  .videocard__container {
    width: 100%;
    margin-left: 0px;
  }
}
