.wrapper {
  display: block;
  width: 100%;
  float: left;
}
.tableWrapper {
  float: left;
  margin-top: 60px;
}
.mr-10 {
  margin-right: 10px;
}
table.accordion {
  width: 100%;
  margin: 10px;
  border: 1px solid #dedede;
}
table.accordion th,
table.accordion td {
  padding: 10px;
}
table.tableWrapper input[type="checkbox"] {
  visibility: visible;
  width: 20px;
  height: 20px;
}
table.tableWrapper,
table.tableWrapper.tr {
  width: 100%;
}
table.tableWrapper thead {
  border-bottom: 3px solid greenyellow;
  padding-bottom: 1%;
}
table.tableWrapper thead th {
  font-size: 16px;
  padding-bottom: 10px;
}
table.tableWrapper input[type="text"] {
  border-color: transparent;
  padding: 5px;
  cursor: pointer;
}
table.tableWrapper input[type="text"]:focus {
  outline: none;
}
.sidebar.features li span {
  border-left: 8px solid transparent;
  padding: 4px 20px;
  display: block;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.sidebar.features li.active span {
  border-left: 8px solid #b4ff00;
  font-weight: 700;
}
