.brandoverviewleftsection__container {
  /* width: 75%; */
  width: 100%;
}

.brandoverviewleftsection__heading {
  font-size: 20.2px;
  font-weight: bold;
  letter-spacing: .5px;
  display: inline-block;
  margin-left: 5%;

}

.brandoverviewleftsection__btn--purple {
  background-color: #8C25F6;
  border-radius: 36px;
  padding: 7px 20px;
  font-size: 12px;
  border: 1px solid #8C25F6;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  margin-left: 2%;
  font-family: 'Montserrat', sans-serif;
}

.brandoverviewleftsection__header--emptylist {
  margin-top: 10vh;
}


@media only screen and (max-width: 990px) {
  .brandoverviewleftsection__container {
    width: 100%;
  }
}