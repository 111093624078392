.facebook-login {
  width: 100%;  
  min-height: 50px;
  border-radius: 20px;
  background-color: #4267B2;
  font-weight: 600;
  font-size: 20px;
  color: white;
  border-color: transparent;
}

