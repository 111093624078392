.fbcard__container {
  width: 25rem;
  border-radius: 6px;
  box-shadow: 0px, 2px rgba(0, 0, 0, 0.15);
  border: 1px solid #DEE2E6;
  position: relative;
  margin-bottom: 4vh;
  background-color: #fff;
  margin-left: 2%;
  margin-top: 20px;
  min-height: 539px;
}

.fbcard__photo--container {
  position: relative;
}

.fbcard__layover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(41, 41, 41, 0.4);
  visibility: hidden;
  border-radius: 8px;
}

.fbcard__btn--edit {
  width: 46%;
  height: 34px;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 43%;
  transform: translateX(-50%) translateY(-50%);
  font-weight: 600;
  font-size: 14px;
  color: #8C25F6;
  background: #FFFFFF;
  border: 2px solid #8C25F6;
  border-radius: 17px;
  visibility: hidden;
  cursor: pointer;
}

.fbcard__photo--container:hover .fbcard__layover

  {
  visibility: visible;
}


.fbcard__photo--container:hover .fbcard__btn--edit
{
  visibility: visible;
}


.fbcard__btn--cta {
  width: 85px;
  height: 22px;
  font-weight: 600;
  font-size: 10px;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 3px;
  float: right;
  margin-right: 4%;
  margin-top: 20px;
}

.fbcard__btn--cta:hover {
  background-color: #000;
  color: #fff;
}

.fbcard__ctaoptions--container {
  padding: 0px;
  font-size: 10px;
  width: 25%;
  text-align: center;
  position: relative;
  left: 71%;
  top: 42px;
  z-index: 10;
  height: 50px;
  overflow-y: auto;
}

.fbcard__ctaoptions--container span:hover {
  background-color: #000;
  color: #fff;
  cursor: pointer;
}

.fbcard__ctaoptions--container::-webkit-scrollbar {
  width: 2px;
}

.fbcard__ctaoptions--container::-webkit-scrollbar-thumb {
  background: #8D02FA;
}



/* .fbcard__disabled {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 41, 41, 0.4);
} */

.fbcard__heading--container {
  margin-left: 4%;
  margin-top: 1%;
}

.fbcard__heading--bold {
  display: block;
  margin-top: 10px;
  font-weight: bold;
  font-size: 12px;
}

.fbcard__issponsored {
  font-size: 12px;
  line-height: 14.63px;
  color: #9C9C9C;
}

.fbcard__subheading {
  display: block;
  margin-top: 20px;
  margin-left: 5%;
  font-weight: 500;
}

.fbcard__btn--change-post {
  float: right;
  cursor: pointer;
}

.fbcard__audience--selector {
  width: 90%;
  margin-left: 5%;
  margin-top: 5px;
}

.fbcard__btn--send-message {
  font-size: 13px;
  width: 50%;
  margin-left: 5%;
  float: right;
  margin-right: 5%;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 5px;
}

.fbcard__url--input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 5px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid #C4C4C4;
  width: 100%;
}

.fbcard__likes--container {
  border-top: 1px solid #DEE2E6;
  font-weight: bold;
  font-size: 12px;
  color: #9C9C9C;
  padding: 5px 5%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #DEE2E6;
}

.fbcard__url {
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
  display: inline-block;
  margin-left: 5%;
}

.fbcard__img--like {
  width: 30px;
  height: 30px;
}



/* fb card new css */
.fbcard__url {
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
  display: inline-block;
  margin-left: 5%;
  text-decoration: underline;
}



.fbcard__ctaoptions--container {
  padding: 0px;
  font-size: 10px;
  width: 107px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 24px;
  z-index: 10;
  height: 50px;
  overflow-y: auto;
  box-shadow: 0px 14px 100px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  background-color: #fff;
  color: #000;
}

.fbcard__ctaoptions--container span {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.fbcard__ctaoptions--container span:hover {
  background-color: #000;
  color: #fff;
  cursor: pointer;
}

.fbcard__ctaoptions--container::-webkit-scrollbar {
  width: 2px;
}

.fbcard__ctaoptions--container::-webkit-scrollbar-thumb {
  background: #8D02FA;
}



/* Media screens */

@media only screen and (max-width: 1000px) {
  .fbcard__container {
    width: 40%;
  }
}

@media only screen and (max-width: 600px) {
  .fbcard__container {
    width: 80%;
    margin-left: 10%;
  }
}