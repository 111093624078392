ul.pagination {
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  top: 1vh;
  right: 1rem;
  position: absolute;
}

ul.pagination li.page-item.active a.page-link {
  color: #ffffff !important;
  background-color: #8d02fa !important;
  border-color: #ea71fa !important;
}

ul.pagination a.page-link:hover {
  background-color: #cf7bff;
  color: white;
}

ul.pagination a.page-link {
  text-align: center;
  box-shadow: none !important;
  border-color: #ced4da !important;
  color: #6b88a4;
}
