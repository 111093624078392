.searchpost__container {
  width: 96%;
  margin-left: 2%;
  border: 1px solid #D8DBDF;
  margin-top: 10px;
  height: 36px;
  padding-top: 7px;
  color: #68686B;
  border-radius: 5px;
}


.searchpost__input {
  border: none;
  font-size: 18px;
  width: 90%;
  margin-left: 2%;
  color: #9DA3AB;
  margin-top: -7px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.searchpost__input:focus {
  outline: none;
}

.searchpost__icon {
  float: right;
  margin-right: 2%;
  margin-top: 2px;
  cursor: pointer;
}