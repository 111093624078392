.enterurlstep__dialog--container {
  width: 22%;
  position: absolute;
  z-index: 999;
  left: 10%;
  top: 20px;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #9C9C9C;
}

.enterurlstep__button--container {
  display: flex;
  width: 80%;
  margin-left: 10%;
  justify-content: space-between;
}

.enterstep__btn {
  height: 20px;
  font-size: 14px;
  border-radius: 35px;
  padding-top: 0px;
}

.enterurlstep__url--container {
  background-color: #fff;
  width: 40%;
  height: 83px;
  position: absolute;
  z-index: 999;
  left: 33%;
  top: 20px;
  border-radius: 5px;
  padding: 5px 10px;
}

.enterurlstep__url--question {
  font-weight: 600;
  font-size: 18px;
}

.urlstep__url--input {
  border: none;
  font-weight: 500;
  font-size: 20px;
}