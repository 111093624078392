.editaudience__disable {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .25);
  z-index: 100;
}

.editaudiencepopup__container {
  width: 60vw;
  height: 75vh;
  position: fixed;
  z-index: 10;
  top: 55%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  border-radius: 10px;
}

/* Css for heading div */
.editaudience__heading--container {
  text-align: center;
  margin-top: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(192, 207, 228);

}

.editaudience__heading {
  font-size: 20px;
  font-weight: bold;
}

.editaudience__icon--close {
  float: right;
  margin-right: 2%;
  font-size: 20px;
  margin-top: 5px;
  cursor: pointer;
}

/* End of css for heading div */


/* Css for buttons div */

.editaudiencepopup__btn--container {
  margin-top: 40px;
  margin-left: 6%;
  margin-bottom: 40px;
}



.editaudiencepopup__btn--media {
  width: 20%;
  height: 40px;
  background-color: #fff;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
}

.editaudiencepopup__btn--media:focus {
  outline: none;
}

/* End of css for buttons div */


/* form container */

.editaudience__form--container {
  width: 100%;
  /* height: 90%; */
  margin-top: 10px;
  overflow-y: auto;
  height: 50vh;
}

/* end of form container */