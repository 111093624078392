.shimmer__container {
  width: calc(100vw * 0.075);
  height: 30px;
  background-color: #777;
}

.shimmer-wrapper {
  width: 0px;
  animation: fullView 0.5s forwards linear;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

.animate {
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

@media only screen and (min-width: 1600px) {
  .shimmer__container {
    width: calc(100vw * 0.08);
  }
}

@media only screen and (max-width: 800px) {
  .shimmer__container {
    width: calc(100vw * 0.05);
    height: calc(100vh * 0.01);
  }
}
