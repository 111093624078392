.carouselcard__container {
  width: 27%;
  border-radius: 6px;
  box-shadow: 0px, 2px rgba(0, 0, 0, 0.15);
  border: 1px solid #dee2e6;
  position: relative;
  margin-bottom: 4vh;
  background-color: #fff;
  margin-left: 2%;
  margin-top: 20px;
  min-height: 539px;
}

.carouselcard__photo--container {
  position: relative;
  display: flex;
}

.carouselcard__layover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(41, 41, 41, 0.4);
  visibility: hidden;
  border-radius: 8px;
}

.carouselcard__btn--edit {
  width: 46%;
  height: 34px;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 43%;
  transform: translateX(-50%) translateY(-50%);
  font-weight: 600;
  font-size: 14px;
  color: #8c25f6;
  background: #ffffff;
  border: 2px solid #8c25f6;
  border-radius: 17px;
  visibility: hidden;
  cursor: pointer;
}

.carouselcard__photo--container:hover .carouselcard__layover {
  visibility: visible;
}

.carouselcard__photo--container:hover .carouselcard__btn--edit {
  visibility: visible;
}

.carouselcard__btn--cta--container {
  float: right;
  margin-right: 4%;
  margin-top: 20px;
  position: relative;
}

.carouselcard__btn--cta {
  width: 74px;
  height: 22px;
  font-weight: 600;
  font-size: 8px;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 3px;
}

.carouselcard__btn--cta:focus,
.carouselcard__btn--cta:active {
  outline: none;
}

.carouselcard__btn--cta:hover {
  background-color: #000;
  color: #fff;
}

.carouselcard__ctaoptions--container {
  padding: 0px;
  width: 74px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 24px;
  z-index: 10;
  height: 50px;
  overflow-y: auto;
  box-shadow: 0px 14px 100px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  background-color: #fff;
  color: #000;
  font-size: 9px;
}

.carouselcard__ctaoptions--container span {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.carouselcard__ctaoptions--container span:hover {
  background-color: #000;
  color: #fff;
  cursor: pointer;
}

.carouselcard__ctaoptions--container::-webkit-scrollbar {
  width: 2px;
}

.carouselcard__ctaoptions--container::-webkit-scrollbar-thumb {
  background: #8d02fa;
}

.carouselcard__heading--container {
  margin-left: 4%;
  margin-top: 1%;
}

.carouselcard__heading--bold {
  display: block;
  margin-top: 10px;
  font-weight: bold;
  font-size: 12px;
}

.carouselcard__issponsored {
  font-size: 12px;
  line-height: 14.63px;
  color: #9c9c9c;
}

.carouselcard__subheading {
  display: block;
  margin-top: 20px;
  margin-left: 5%;
  position: relative;
  font-weight: 500;
  font-size: 16px;
}

.carouselcard__btn--change-post {
  float: right;
  cursor: pointer;
}

.carouselcard__audience--selector {
  width: 90%;
  margin-left: 5%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.carouselcard__btn--send-message {
  font-size: 13px;
  width: 50%;
  margin-left: 5%;
  float: right;
  margin-right: 5%;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 5px;
}

.carouselcard__url--input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 5px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  width: 100%;
}

.carouselcard__likes--container {
  border-top: 1px solid #dee2e6;
  font-weight: bold;
  font-size: 12px;
  color: #9c9c9c;
  padding: 5px 5%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
}

.carouselcard__url {
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
  display: inline-block;
  margin-left: 5%;
  text-decoration: underline;
}

.carouselcard__img--like {
  width: 30px;
  height: 30px;
}

.carouselcard__input--headline {
  width: 100%;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: none;
  /* border-bottom: 1px solid #000; */
  font-weight: 500;
}

.carouselcard__input--headline:active,
.carouselcard__input--headline:focus {
  outline: none;
  border-bottom: 1px solid #000;
}

.carouselcard__button--play {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 10;
  left: 50%;
  top: 31%;
  transform: translateX(-50%) translateY(-50%);
}

.carouselcard__span--message {
  display: block;
  margin-left: 5%;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* wasim css */

.carouselcard__photo--container {
  display: block;
  height: 420px;
}

.slick-slide,
.slick-slide > div,
.slick-track,
.slick-slider .slick-track,
.slick-slider,
.slick-list {
  height: 100% !important;
}

.imageBox {
  height: 100%;
  width: 120% !important;
}

.imageBox img {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  height: 66%;
  min-height: 66%;
}

.slick-list {
  padding: 0 20% 0 0 !important;
}

.slick-next {
  right: 0px;
  z-index: 9;
}

.slick-prev {
  left: 0px;
  z-index: 9;
}

.slick-slide.slick-active.slick-center.slick-current {
  margin-right: 55px;
}

.carouselcard__photo--container .slick-dots {
  bottom: 25px;
  z-index: 9;
}

/* .imageBox .react-thumbnail-generator {
  width: 100%;
  max-width: 100%;
  height: auto;
} */

.imageBox .react-thumbnail-generator {
  width: 100%;
  max-width: 100%;
  height: 278px !important;
  overflow: hidden;
}

.imageBox .react-thumbnail-generator img {
  width: 100%;
  max-width: 100%;
  height: 278px !important;
  min-height: 278px !important;
}

@media only screen and (max-width: 1000px) {
  .carouselcard__container {
    width: 40%;
  }
}

@media only screen and (max-width: 600px) {
  .carouselcard__container {
    width: 100%;
    margin-left: 0px;
  }
}
