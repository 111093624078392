.emptybrandprofilesbox__container {
  width: 60%;
  height: 80px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background-color: #fff;
  margin-top: 10vh;
  margin-left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}