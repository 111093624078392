.navbar__btn--user {
  background-color: transparent;
  border: none;
}

.navbar__btn--user:active,
.navbar__btn--user:focus {
  outline: none;
}





.msg{

  border-bottom: 1px solid #F0F0F0;
  padding-bottom:10px;
  padding-top:10px;
  margin-bottom:10px;
  background-color: #F0F0F0;
  word-break: break-all;
  padding-inline-start: 15px;
  cursor: pointer;
}


