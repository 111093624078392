/* Other css starts here */
.selectposttable__container {
  padding: 1rem;
}

.selectposttable__table {
  display: inline-block;
  border-spacing: 0;
  border: 1px solid #C9C9C9;
  width: 100%;
}

.selectposttable__th:last-child,
.selectposttable__td:last-child {
  border-right: 0;
}

.selectposttable__th {
  font-weight: 500;
}

.selectposttable__th,
.selectposttable__td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #C9C9C9;
  border-right: 1px solid #C9C9C9;
}

.selectposttable__post--container>img {
  width: 40px;
  height: 40px;
}

.selectposttable__post--span {
  /* font-size: 8px; */
  font-weight: bold;
  margin-left: 5px;
}

.selectposttable__btn {
  font-size: 14px;
}

.selectposttable__btn.continue {
  width: calc(100vw * 0.15);
}

.selectposttable__btn.cancel {
  width: calc(100vw * 0.10);
}


@media only screen and (min-width: 900px) and (max-width: 1000px) {
  .selectposttable__table {
    font-size: 10px;
  }
}

@media only screen and (max-width: 900px) {
  .selectposttable__table {
    /* font-size: 8px; */
  }
}

@media only screen and (max-width: 800px) {
  .selectposttable__post--span {
    /* font-size: 6px; */
  }
}

@media only screen and (max-width: 700px) {
  .selectposttable__table {
    font-size: 6px;
  }

  .selectposttable__container {
    padding: 1rem .5rem;
  }

}

@media only screen and (max-width: 550px) {
  .selectposttable__btn.continue {
    width: calc(100vw * 0.3);
  }

  .selectposttable__btn.cancel {
    width: calc(100vw * 0.2);
  }
}

@media only screen and (max-width: 650px) {
  .selectposttable__post--container>img {
    
  }

  .selectposttable__post--span {
    
  }
  
}

@media only screen and (max-width: 600px) {
  .selectposttable__post--container>img {
   
  }

  .selectposttable__post--span {
    
  }
  
}

@media only screen and (max-width: 550px) {
  .selectposttable__table {
    font-size: 12px;
  }

  .selectposttable__container {
    padding: 1rem .2rem;
  }

}

@media only screen and (max-width: 500px) {
  .selectposttable__post--span {
   
  }
}

@media only screen and (max-width: 400px) {
  .selectposttable__post--container>img {
    
  }
}
