.selectpostpopup__container {
  width: 60%;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  /* transform: translateX(-50%) translateY(-50%); */
  z-index: 999;
  padding-bottom: 10px;
  border-radius: 5px;
  min-height: 30rem;
}

.selectpostpopup__heading {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e7ea;
  padding-left: 5%;
  padding-right: 3%;
  padding-top: 15px;
  font-weight: bold;
  font-size: 16px;
}

.selectpostpopup__container--btn {
  padding-top: 10px;
  float: right;
  margin-right: 5%;
  display: block;
  font-size: 12px;
  width: 90%;
}

.selectpostpopup__btn--grey {
  font-weight: bold;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid #dddddd;
}

.selectpostpopup__btn--blue {
  font-weight: bold;
  background-color: #1876d2;
  color: #fff;
  border: 2px solid #1876d2;
  margin-left: 20px;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 2px;
}

.selectpostpopup__icon--close {
  font-size: 20px;
  margin-top: 2px;
  cursor: pointer;
}

.carousal-content {
  display: flex;
  width: 100%;
  height: 30rem;
}
.carousal-content img {
  width: 100%;
  height: 15rem;
  margin: 0;
  min-height: auto;
  object-fit: cover;
}

.drag-content {
  width: 250px;
  height: 15rem;
  /* max-height: 204px;
  min-height: 204px; */
  position: relative;
}

.drag-content:hover .carouselcard__btn--edit,
.drag-content:hover .carouselcard__layover {
  visibility: visible;
}

.drag-content > div {
  height: 100%;
}

.drag-content img.videocard__button--play {
  z-index: 0;
}

.d-flex .imagecard__btn--cta--container {
  float: right;
  margin-right: 0;
  margin-top: 10px;
  position: relative;
}
.imagecard__btn--cta--container--carousel {
  position: absolute;
  right: 0;
  top: 0.5rem;
}
.d-flex .imagecard__url {
  width: 50%;
}

.carousal-content .addpostcard__container {
  margin: 20px 30px;
  /* min-height: auto; */
}

.carousal-content .addpostcard__container img {
  width: 40px;
  height: auto;
  transform: translate(0px, 0px);
}

.carousal-content .addpostcard__container span {
  font-size: 14px;
}

.carousal-content .addpostcard__container > div {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.carousel-error-check {
  position: absolute;
  color: crimson;
  top: 76px;
    left: 14px;
    
}

.carasoulpopup__imagecard--ctaoptions {
  height: 65px;
  padding: 0px;
  font-size: 10px !important;
  width: 100% !important;
  text-align: center;
  position: absolute;
  left: 0;
  top: 24px;
}
/* ------ Media css ----- */
@media only screen and (max-width: 700px) {
  .selectpostpopup__container--btn {
    font-size: 10px;
  }
  .selectpostpopup__btn--grey {
    padding: 3px 10px;
  }
  .selectpostpopup__btn--blue {
    padding: 3px 10px;
  }
  .selectpostpopup__container{
    width: 90%;
  }
}
