.sidebar-nav-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 12px 0px;
    font-weight: 500;
    color: white;
  }
  .sidebar-nav-item p{
    margin-top: 5px;
  }

  @media only screen and (max-width:600px){
    .sidebar-logo{
      width: 150px;
    }
    .sidebar-nav-item{
      color: black;
    }
    
  }