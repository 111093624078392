.billing-box{
  padding: 20px 20px 20px 140px;
  box-sizing: border-box;
  margin-top: 5vh;
  max-width: 1200px;
  margin: auto;
}
.billing-single {
  text-align: center;border: 2px solid #C4C4C4;
  transition: 0.5s;border-radius: 6px;
  padding: 40px 15%;
  margin-bottom: 30px;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.billing-single:hover{
  border: 2px solid #70FF00;
}
.billing-single .large-price{
  font-size: 35px;font-weight: 900;color: #000;
  margin: 0 0 15px;
  text-transform: uppercase;

}
.billing-single .large-price-alt{
  font-size: 36px;font-weight: 900;color: #000;
  margin: 0 0 15px;
}
.billing-single .large-price .small-text{
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1.3;
  margin-top: 15px;
}

.billing-single .large-price-alt .small-text{
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1.3;
  margin-top: 15px;
}

.font-weight{font-weight:900;}
.font-weight:hover{color:#fff;}
.billing-single .small-price{
  font-size:35px;font-weight: 800;color: #000;margin: 0 0  30px;
}
.billing-single .small-price .small-text{
  display: block;font-size: 16px;line-height: 1.3; margin-top: 20px;
  font-weight: 700;
}
.billing-single .button {
    width: 100%;
    text-transform: uppercase;
    font-weight: 900;
    padding: 15px 20px;
    border: 0;
    box-shadow: 2px 10px 20px rgba(141,2,250, 0.16);
}

.billing-single .button.active {
  background: #70FF00;
}

.billing-single .button.inactive {
  background: rgb(255, 0, 0);
}

.billing-single .smaller-text{
  color: grey;
  font-size: 15px;
  text-transform: uppercase;
}



/* .billing-single:hover .button{
 background: #B6B6B6;
} */
.price-list {
  padding: 0;
  margin: 0 0 30px;
  text-align: left;
}
.price-list li {
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom:10px;
}
.price-list li  img{
  margin-right: 10px;
}
.d-flex{
  display: flex;
  flex-wrap: wrap;
}
.align-items-center{
  align-items: center;
}
.transaction-detailbox .col-left{
  margin-bottom: 20px;
}
.transaction-detailbox .col-left .text-box{
  margin-left: 10px;color: #747474;
  font-weight: 700;font-size: 19px;
}
.transaction-detailbox .col-right {
  justify-content: flex-end;margin-bottom: 10px;
}
.transaction-detailbox .col-right .history-text{
  margin-right: 10px;color: #000;
  font-weight: 700;font-size: 14px;
}
.transaction-table{
  margin-top: 30px;
}
.transaction-table>tbody>tr>td{
  border: 0;
  padding: 15px 30px;
  font-size: 14px;
}
.transaction-table>thead>tr>th {
  background:#B4FF00;border: 0;font-size: 14px;
  padding: 15px 30px;
}
.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #fff;
}
.table-striped>tbody>tr:nth-of-type(even) {
  background-color: #f9f9f9;
}
.button.button-outline{
  background-color: transparent;
  color: #8d02fa;
  text-align: center;
  border: 1px solid #8d02fa;
}
.button.button-outline:hover{
  background-color: #8d02fa;
  color: #fff;
}

#loader-4{
  
  text-align: center;
}

#loader-4 span{
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #70FF00;
  margin: 35px 5px;
  opacity: 0;
  
}

#loader-4 span:nth-child(1){
  animation: opacitychange 1s ease-in-out infinite;
}

#loader-4 span:nth-child(2){
  animation: opacitychange 1s ease-in-out 0.33s infinite;
}

#loader-4 span:nth-child(3){
  animation: opacitychange 1s ease-in-out 0.66s infinite;
}

@keyframes opacitychange{
  0%, 100%{
    opacity: 0;
  }

  60%{
    opacity: 1;
  }
}


@media only screen and (max-width:600px){
  .billing-box{
    padding: 15px;
    box-sizing: border-box;
    margin-top: 10px;
  }
}
