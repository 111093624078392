.selectctastep__dialog--container {
  width: 22%;
  position: absolute;
  z-index: 999;
  left: 5%;
  top: 380px;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #9C9C9C;
}