.createBrand-box{
    width: 100%;
    border: 2px solid rgb(231 231 231);
    border-radius: 5px;
    padding: 10px 40px 20px;
    margin-bottom: 16px;
}

 .radio .MuiIconButton-label {
   color: #8C25F6 ;
}

.question-description{
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6;
}


#facebook_adds {
    max-height: 350px;
    overflow-y: scroll;
}

.group-horizontal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.button.connect-saved-btn {
    margin-bottom: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: #7a7a7a;
    background-color: #fff;
    color: #000;
    font-size: 12px;
}

.button {
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px;
    padding: 10px 24px;
    border-radius: 50px;
    background-color: #8d02fa;
    font-family: Montserrat, sans-serif;
    color: #fff;
    text-align: center;
}

.brandoverviewleftsection__btn--purple {
    background-color: #8C25F6;
    border-radius: 36px;
    padding: 7px 20px;
    font-size: 12px;
    border: 1px solid #8C25F6;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    font-weight: 700;
    margin-left: 2%;
    font-family: 'Montserrat', sans-serif;
  }
  .brandoverviewleftsection__btn--purple:focus{
    outline: none;
    
  }
  .brandprofilecard__btn--white {
    border-radius: 36px;
    font-size: 11px;
    font-weight: 600;
    padding: 8px 24px;
    color: #8D02FA;
    border: 1px solid #8D02FA;
    background-color: #fff;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;

  }
  .brandprofilecard__btn--white:focus{
    outline: none;
    
  }
.disabled-btn{
    border-radius: 36px;
    font-size: 11px;
    font-weight: 600;
    padding: 8px 24px;
    color: #8f8f8f;
    border: 1px solid #8f8f8f;
    background-color: #fff;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
}
.w-button {
    display: inline-block;
    padding: 9px 15px;
    background-color: #3898EC;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
}

.icon-small {
    margin-right: 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

h4.connect-acc-label {
    margin-top: 4px;
    margin-bottom: 4px;
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
}

.tab-content > .tab-pane {
    min-height: 100vh;
}

input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}
.tab-content{
    padding: 10px;
}
.connect-acc-list.title-label {
    background-color: #f2f2f2;
    font-weight: 700;
}

.toggle {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 20px;
    background: grey;
    display: block;
    border-color: #e5e5e5;
    border-radius: 100px;
    position: relative;
}

.toggle:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    border-color: #e5e5e5;
    background: #fff;
    border-radius: 90px;
    transition: 0.5s;
}

input:checked + .toggle {
    background: #B4FF00;
}

input:checked + .toggle:after {
    left: calc(100% - 0px);
    transform: translateX(-100%);
}

.toggle:active:after {
    width: 10px;
}

.connect-acc-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 15px 16px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
}

.acc-id {
    width: 75px;
    margin-right: 10px;
    font-size: 12px;
}

.acc-toggle {
    margin-top: -15px;
}

.div-block-73 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 15px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.funnel-heading-conversions{
    position: absolute;
    top: 33px;
    left: 140px;
    color: rgb(255, 255, 255);
    font-size: 20px;
}
.funnel-heading-lead-generation{
    position: absolute;
    top: 5px;
    left: 140px;
    color: rgb(255, 255, 255);
    font-size: 20px;
}

.graph-div{
    width: 500px;
    position: relative;
    margin: auto;
    border: 2px solid #e1e1e1;
    border-radius: 5px;
    padding-bottom: 10px;
}

.create-brand-last-step-box{
    border: 2px solid #ececec;
    padding: 10px;
    border-radius: 5px;
}

.brand-custom-form{
    margin-top: 40px ;
  }

.funnel-graph-img{
    width: 500px;
}
.lead-funnel-graph{
    display: flex;
    position: absolute;
    top: 62px;
    left: -45px;
    text-align: center;
    color: white;
    width: 500px;
    justify-content: center;
}
.conversion-funnel-graph{
    display: flex;
    position: absolute;
    top: 60px;
    left: -68px;
    text-align: center;
    color: white;
    width: 500px;
    justify-content: center;
}

.graph-text{
    margin-bottom: 7.5px;
}

.brand-mobile-view-container{
    padding: 0 0 0 120px;
}



@media only screen and (max-width: 600px){
    .createBrand-box{
        padding: 10px 0px 20px;
        border: none;
    }
    .question-description{
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.4;
        text-align: justify;
    }

    .graph-div{       
        border: none;       
    }

    .MuiTypography-h6.graph-heading{
        margin-top: 40px;
    }
    .create-brand-last-step-box{
    border: none;   
    }
    .brand-custom-form{
        margin-top: 10px ;
    }

    /* .navination_bar{
        position: fixed;
        bottom: 100px;
        right: 20px;
    } */
    .createBrand-box{
        height: 45vh;
        overflow: auto;
    }

    .funnel-graph-img{
        width: 360px;
    }
    .lead-funnel-graph{
        top: 44px;
        left: -32px;
        width: 360px;
      
    }
    .conversion-funnel-graph{
        top: 42px;
    left: -50px;
        width: 360px;
       
    }
    .graph-text{
        margin-bottom: 2.8px;
        font-size: 12px;
    }
    .brand-mobile-view-container{
        padding: 10px;
    }
}

