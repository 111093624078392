.UserTeamWrapper{
  padding: 20px 20px 20px 140px;
  box-sizing: border-box;
  margin-top: 5vh;
  max-width: 1200px;
  margin: auto;
}
.userTeamTable{
    color:#A5A5A5;font-weight: 700;
    line-height: 1.6;
    border-collapse: separate;
     border-spacing: 0px 20px;margin: 0;
  }
  .userTeamTable .d-block{
  display: block;
  }
  
  .userTeamTable .dark-text{
    font-weight: 500;color: #000;
    /* background: white; */
  }
  .userTeamTable .purple-text{
    color: #8C25F6;font-weight: 700;
  }
  .userTeamTable .d-flex{
    display: flex;align-items: center;
  }
  .userTeamTable .btnbox{
        justify-content: flex-end;
  }
  .userTeamTable .btnbox .button {
      border: 0;
      border-radius: 50px;
      color: #fff;
      text-transform: uppercase;
      padding: 5px 20px;
      font-size: 12px;
      text-decoration: none;
      margin-right: 20px;
  }
  .userTeamTable .active-text{
  color: #7EB200;
  }
  .userTeamTable .pending-text{
    color: #FF9900;
  }
  .userTeamTable tr td{
    padding: 20px 35px;
     border-top: 1px solid #DEE2E6;
     border-bottom: 1px solid #DEE2E6;
     width: 20%;
     vertical-align: middle;
  }
  .userTeamTable tr td:first-child{
      border-left: 1px solid #DEE2E6;
  }
  .userTeamTable tr td:last-child{
      border-right: 1px solid #DEE2E6;
  }
  .userTeamTable tr td:nth-child(3){
    width: 25%;
  }
  .userTeamTable tr td:nth-child(4){
    width: 12%;
  }
  .userTeamTable .btnbox .dark-text{
    border: 0;
  }
  .invite-users-box .btn-list{
    margin: 0;padding: 0;
  }
  .invite-users-box .btn-list li{
   list-style: none;margin: 0;padding: 32px 30px; border: 1px solid #DEE2E6;border-radius: 4px;
   margin-bottom: 20px;
   text-align: center;
  }
  .invite-users-box .btn-list li a{
    font-weight: 700;color: #8C25F6;text-decoration: none;
  }
  .userTeamTable input[type="number"] {

    -webkit-appearance: none;
    margin-left: 4px;
    color: #8C25F6;
    font-weight: 700;
    width: 50px;
}
.userTeamTable input[type=number]::-webkit-inner-spin-button, 
.userTeamTable input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
 .userTeamTable input[type=number]::placeholder {
color: #8C25F6;
  opacity: 1; /* Firefox */
}
.dNone {
  display: none;
}
@media(max-width:1366px){

}


@media only screen and (max-width: 600px){
  .UserTeamWrapper{
    padding: 10px;
    box-sizing: border-box;
    margin-top: 10px;
  }

  .userTeamTable tr td{
    padding: 5px 15px 5px;
     border-top: 1px solid #DEE2E6;
     border-bottom: 1px solid #DEE2E6;
     width: 20%;
     vertical-align: middle;
  }
}
