.popupheader__container {
  display: flex;
  color: #727880;
  border-bottom: 1px solid #E5E7EA;
  padding-top: 10px;
  /* padding-bottom: 10px; */
}

.popupheader__text {
  cursor: pointer;
}

.popupheader__container .active {
  background-color: blue;
}

