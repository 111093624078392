.postboost__btn--back {
  border: 1px solid #8D02FA;
  background-color: #fff;
  color: #8D02FA;
  border-radius: 20px;
  float: right;
  margin-right: 10px;
  padding: 0.375rem 0.75rem;
  margin-top: 20px;
  cursor: pointer;  
  font-weight: 600;
}
.right-btn{
  margin-right: 60px;
}


.post-boost-creative-container{
  
    display: flex;
    flex-flow: row wrap;
    margin-top: 10px;
    width: 100%;
    padding: 0px 45px;
 
}
.postboost__btn--back:hover {
  background-color: #8D02FA;
  color: #fff;
}

.post-boost-screen{
  box-sizing: border-box;
  padding: 0 0 0 120px;
  background-color: #fcfbff;
  padding-bottom: 120px;
}
.campaigninfobox__container{
  background-color: white;
}
.postboost-save-actions button:last-child{
  margin-left: 10px;
}


@media only screen and (max-width:600px){
  .post-boost-screen{
    padding: 0px;
  }
  .post-boost-screen{
   padding: 0px 10px 120px;
  }

  .campaigninfobox__container {
    margin: 0px;
    width: 100%;
    padding: 10px;
  }
  .predictionstats__container{
    padding: 0px;
    /* position: unset; */
  }
  .right-btn{
    margin-right: 10px;
  }
  .post-boost-creative-container{
    padding: 0px 0px;
}
.postboost-save-actions{
  flex-direction: column;
  flex-flow: column-reverse;
  left: 50%;
    position: fixed;
    width: 100%;
    padding: 0px 20px 10px;
    transform: translate(-50%, 0%);
    z-index: 100;
    bottom: 0px;
    background: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}
.postboost-save-actions button:last-child{
  margin-right: 0px;
}
.postboost-save-actions button{
  margin-left: 0px !important;
}
}
.post-boost-screen .MuiTabs-flexContainer{
  justify-content: space-between;
}

.tab-text{
  text-align: center;
  font-size: 10px;
  margin-top: 5px;
  text-transform: capitalize;
}

.post-boost-screen .MuiAppBar-positionFixed{
  color: black;
  top: 56px;
  background-color: white;

}