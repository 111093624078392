.description__span {
  font-size: 14px;
  /* display: inline-block; */
  margin-top: 10px;
  margin-left: 10%;
  margin-bottom: 10px;
  width: 80%;
}

.description__span--more {
  /* margin-left: 10%; */
  color: blue;
  cursor: pointer;
}

.description__textarea {
  font-weight: 500;
  width: 100%;
  border: none;
  font-size: 20px;
  margin-bottom: 10px;
}

.description__textarea:active,
.description__textarea:focus {
  outline: none;
}