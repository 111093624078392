.ApiKeyWrapper{
    padding: 20px 20px 20px 140px;
  box-sizing: border-box;
  margin-top: 5vh;
  max-width: 1200px;
  margin: auto;
}
.api-key-container {
    padding: 5px;
    background-color: #efefef;
    border-radius: 4px;
    cursor: copy;
}

.click-2-create-api-key {
    color: #2196f3;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
}

.click-2-create-api-key:hover {
    text-decoration: underline;
    cursor: pointer;
}

.add-integration-form {

}

.add-integration-form input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
    /* font-weight: bold; */
    margin-right: 0%;
    /* color: #979797; */
    font-size: 18px;
    padding: 10px;
}

.add-integration-form select {
    font-size: 16px;
    color: rgb(151, 151, 151);
    padding: 12px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(226, 226, 226);
    font-weight: bold;
}
@media only screen and (max-width:600px){
    .ApiKeyWrapper{
        padding: 10px;
      box-sizing: border-box;
      margin-top: 10px;
      max-width: 1200px;
      margin: auto;
    }
  }