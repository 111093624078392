.postboostchartcomponent__container {
  width: 90%;
  margin-left: 5%;
  /* box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.08); */
  margin-top: 20px;
  border-radius: 10px;
}

.postboostchartcomponent__container .parentWrap{
  padding: 0px;
}

.postboostchartcomponent_loader {
  margin: auto;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #8d02fa;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.postboost_error .MuiSvgIcon-root {
  
  width: 16em;
  height: 7em;
  color: red;
  margin-top: 2rem;
}

@keyframes spin {
  from {
    transform: rotateZ(0);
  }

  to {
    transform: rotateZ(360deg);
  }
}
