.integration__img--tick {
  position: absolute;
  top: 5px;
}

.integation__span--tick {
  margin-left: 15%;
}

.integration__span--desc {
  font-weight: bold;
  margin-top: 20px;
}