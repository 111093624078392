.qualification-dropdown {
  .MuiFormControl-root {
    .MuiSelect-select {
      // font-family: "Montserrat", sans-serif !important;
      min-width: 180px;
      border: none;
      color: #6e6893;
      padding-top: 3px;
      padding-bottom: 3px;
    }
    .MuiSelect-icon {
      color: #8d02fa;
    }
  }
}
