.clientlistingtable_th_backgroud {
  background-color: #f4f2ff !important;
}

#lead_listing_table {
  border: 1px solid #d9d5ec;
  border-radius: 2px;
  text-align: center;
  margin-bottom: 0 !important;
}

#lead_listing_table thead {
  /* font-family: "Alegreya", serif; */
}

#lead_listing_table tbody td {
  position: relative;
  /* 	 padding-right: 10px; */
}

#lead_listing_table tbody td:after {
  content: "";
  height: 21px;
  width: 1px;
  background-color: #d9d5ec;
  position: absolute;
  top: 35%;
  left: 100%;
}

.table-footer ul {
  margin: 0;
  padding: 0;
}

.clientlistingtable_table_td {
  color: #6e6893;
}

.clientlisting_btn:focus {
  outline: none;
  box-shadow: none;
}

.fa-chevron-down,
.fa-chevron-up {
  color: #8d02fa;
}

.qualificationdropdown {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(60px, 48px, 0px);
}

.clientlistingtable_wrapper_shimmer__container {
  width: 100%;
  height: 30px;
  background-color: #777;
}

.clientlistingtable_wrapper {
  width: 0px;
  animation: fullView 0.5s forwards linear;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

.animate {
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

@media only screen and (min-width: 1600px) {
  .clientlistingtable_wrapper_shimmer__container {
    width: calc(100vw * 0.88);
  }
}

@media only screen and (max-width: 800px) {
  .clientlistingtable_wrapper_shimmer__container {
    width: calc(100vw * 0.84);
  }
}
