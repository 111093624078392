.dashboard{
    padding: 20px 20px 20px 140px;
    background-color: #F4F2FF;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
}
.promotions{
    overflow-x: auto;
    display: flex;
}
.promotion-box{
    flex: 33.33%;
    box-sizing: border-box;  
}
.promotion-box:first-child{   
    margin-right: 10px;   
}
.promotion-box:last-child{
    margin-left: 10px;
}
.dashboard-title{
    display: flex;
    padding-left: 5px;
    align-items: center;
}
.switch-brands{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5px;
}
.switch-brands p{
    font-size: 10px;
}
.details{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.details-box{
    width: 18%;
    min-width: 250px;
}
.box{
    border-radius: 8px;
    padding: 10px;
    border: .5px solid rgb(212, 212, 212);
    background-color: white;
    margin: 0px 5px;
    height: 100%;
    box-shadow: rgba(215, 172, 226, 0.2) 0px 2px 8px 0px;
}
.icon{
    margin: 5px;
    margin-right: 10px;
}
.ad-spent-box{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.ad-spent.MuiTypography-colorPrimary{
    font-weight: bold;
}

.dashboard .MuiButton-outlinedPrimary{
    text-transform: capitalize;
}

.chip-container{
    display: flex;
    overflow-x: scroll;
    box-sizing: border-box;
}

.chips{
    /* flex: 1; */
    min-width: 220px;
    margin-right: 10px !important;
}

.profile-select .MuiInputLabel-outlined{ 
    transform: translate(14px, 14px) scale(1);
}

.profile-select .MuiOutlinedInput-input  {
    padding: 8.5px 14px;
    font-size: 12px;
}
.profile-select .MuiInputBase-root{
    font-weight: 500px;
}


.stacked-bar-chart-parent {
    width: 100%;
    min-height: 80px;
    align-items: center;
    justify-content: center;
}

.dashboard .MuiSelect-iconOutlined{
    color: #b4ff00;
  }

  .dashboard .dashboard-section-heading{
      font-size: 20px;
      margin-left: 5px;
  }
  .dashboard .MuiButton-outlinedSizeSmall{
      font-size: 14px;
      min-width: 80px;
  }
  .dashboard .MuiSelect-outlined.MuiSelect-outlined{
      font-size: 14px;
      color: #8d02fa;
      font-weight: 400;
  }

.dashboard .MuiOutlinedInput-notchedOutline {
    border-color: #8d02fa;
}
.dashboard .MuiInputLabel-outlined{
    color: #8d02fa;
}

.dashboard-img{
    width: 12px;
    height: 12px;
}

@media only screen and (max-width:600px){
    .dashboard{
        padding: 5px;
    }
    .box{
        margin: 0px;
    }
    .dashboard-btn{
        font-size: 12px;
    }
    .dashboard-data-byte-text{
        font-size: 14px !important;
    }
}





