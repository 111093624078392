.enteraudiencestep__dialog--container {
  width: 22%;
  position: absolute;
  z-index: 999;
  left: 10%;
  top: 120px;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #9C9C9C;
}

.enteraudiencestep__audience__list--container {
  overflow-y: auto;
  height: 200px;
}

.enteraudiencestep__audience__list--container::-webkit-scrollbar {
  width: 5px;
}

.enteraudiencestep__audience__list--container::-webkit-scrollbar-thumb {
  background: #8D02FA;
}

.enteraudiencestep__audience--container {
  background-color: #fff;
  width: 40%;
  height: 404px;
  position: absolute;
  z-index: 999;
  left: 33%;
  top: 120px;
  border-radius: 5px;
  padding: 5px 10px;
}

.enteraudiencestep__audience--box {
  display: flex;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  width: 95%;
  margin-left: 2.5%;
  padding-top: 10px;
  margin-bottom: 10px;
}

.enteraudiencestep__audience--box.active .audiencebox__radio--container .checkmark:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  height: 15px;
  width: 15px;
  background-color: #8D02FA;
  border-radius: 50%;
}

.createcardstep__span--text {
  font-weight: 500;
  font-size: 14px;
  color: #A5A5A5;
}

/* Css for radio button starts here */

.audiencebox__radio--container {
  position: relative;
  left: 2%;
  top: 7px;
}

.audiencebox__radio--container>input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}


.audiencebox__radio--container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background: #FFFFFF;
  border: 1px solid #B5B5B5;
  border-radius: 50%;
}

.audiencebox__radio--container:hover .checkmark:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  height: 15px;
  width: 15px;
  background-color: #8D02FA;
  border-radius: 50%;
}

.audiencebox__radio--container>input:checked~.checkmark {
  background-color: #2196F3;
}


/* CSS for radio button ends here */

.audiencebox__audience--name {
  font-weight: bold;
  font-size: 14px;
  display: block;
  margin-left: 27%;
}

.audiencebox__population--size {
  font-weight: bold;
  font-size: 12px;
  color: #8D02FA;
  margin-left: 27%;
}

.audiencebox__interest {
  color: #A5A5A5;
  font-size: 12px;
}

.enteraudiencestep__buttons--container {
  position: absolute;
  bottom: -40px;
  right: 0;
}

.enteraudiencestep__btn--back {
  background: #FFFFFF;
  border: 1px solid #8C25F6;
  box-sizing: border-box;
  border-radius: 17px;
  width: 100px;
  height: 34px;
  color: #8C25F6;
  font-weight: 600;
  margin-right: 10px;
  cursor: pointer;
}

.enteraudiencestep__btn--create {
  width: 163px;
  height: 34px;
  border-radius: 17px;
  font-weight: 600;
  font-size: 14px;
}