/*================index_page==============================*/

.insight_sec {
  /*    padding-top: 80px;*/
  padding-bottom: 120px;
}

.dash_link {
  display: block;
  margin-bottom: 48px;
}

.dash_link > a.back_link,
.camp_lft > h3,
.camp_lft > p,
.Status,
.drop_row .drop_col select,
.dash_box_col > p.box_head,
.dash_box_col > span.pointer {
  font-family: Montserrat;
  font-style: normal;
}

.dash_link > a.back_link,
.camp_lft > p,
.drop_row .drop_col select {
  font-weight: normal;
}

.dash_link > a.back_link {
  font-size: 16px;
  line-height: 20px;
  color: #8d00fa;
  margin-bottom: 48px;
}

.camp_lft > h3 {
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 3px;
}

.camp_lft > p {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.camp_rgt,
.camp_sec {
  display: flex;
  align-items: center;
}

.camp_sec {
  justify-content: space-between;
  margin-bottom: 32px;
}

.camp_rgt {
  width: 20%;
  justify-content: flex-end;
}

.camp_rgt .switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
  margin-bottom: 0px;
}

.camp_rgt .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.camp_lft {
  width: 80%;
}

.camp_rgt .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.camp_rgt .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.camp_rgt input:checked + .slider {
  background-color: #b4ff00;
}

.camp_rgt input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.camp_rgt input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

.camp_rgt .slider.round {
  border-radius: 34px;
}

.camp_rgt .slider.round:before {
  border-radius: 50%;
}

.Status {
  color: #97d600;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-right: 16px;
}

.drop_row,
.dash_box_row,
.graph_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.drop_row {
  margin-bottom: 24px;
  justify-content: flex-start;
}

.drop_row .drop_col {
  width: 22%;
  margin-right: 20px !important;
}

.drop_row .drop_col .css-2b097c-container {
  width: 100%;
  padding: 10px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  border: none;
  font-size: 16px;
  line-height: 20px;
  -webkit-appearance: none;
  /* background: #ffffff url(../images/drop_angle.svg) no-repeat; */
  background-position: 95% 22px;
  background-size: 16px;
  color: #000000;
}
.drop_row .drop_col .css-2b097c-container .css-1laao21-a11yText {
  border: none;
}
.drop_row .drop_col .css-2b097c-container .css-yk16xz-control {
  border: none;
}
.drop_row .drop_col .css-2b097c-container .css-1okebmr-indicatorSeparator {
  width: 0px;
}

.drop_row .drop_col .css-1laao21-a11yText {
  border: 0 !important;
  outline: none;
}

.drop_row .drop_col.col_ext {
  width: 30%;
}

.dash_box_col {
  /* width: 15.5%; */
  height: 180px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  text-align: center;
  padding: 28px 10px 25px;
  /* padding: 34px 25px 25px; */
}

.dash_box_col > p.box_head {
  margin-bottom: 26px;
}

p.box_head {
  font-size: 14px;
  line-height: 17px;
  color: #000;
}

span.pointer {
  color: #8d00fa;
  font-weight: bold;
}

.dash_box_col > span.pointer {
  font-size: 32px;
  line-height: 54px;
}

.dash_cont > p.box_head {
  margin-bottom: 8px;
}

.dash_cont {
  margin-bottom: 12px;
}

.dash_cont > span.pointer {
  font-size: 24px;
  line-height: 29px;
}

.graph_col {
  /* width: 31.1%; */
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  padding: 10px;
  min-height: 277px;
}

.graph_col.full_width {
  width: 100%;
}

.mob_view {
  display: none;
}

.show_desktop {
  display: block;
}

@media (max-width: 767px) {
  .show_desktop {
    display: none;
  }

  .mob_view {
    display: block;
  }
}

.graph_col.half_width {
  width: 50%;
}

.boxADS {
  border: 1px solid #e5e5e5;
  padding: 20px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}

.parentWrap {
  /* padding: 10px; */
}

.slider-row {
  /* margin: 0px 0px 0px -30px; */
  width: 100%;
  display: flex;
}

/* ==== SLICK CARAUSEL ===== */
/* .insight_sec .slick-slider.slick-initialized,
.insight_sec .slick-list {
  width: 100% !important;
} */

.stats-slider .slick-prev:before,
.stats-slider .slick-next:before {
  color: #97d600;
}
.slick-slider.slick-initialized,
.slick-list {
  width: 100% !important;
}

/* === Date change picker ====*/

.DateRangePicker {
  width: 100%;
  padding: 10px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  border: none;
  font-size: 16px;
  line-height: 20px;
  -webkit-appearance: none;
  /* background: #ffffff url(../images/drop_angle.svg) no-repeat; */
  background-position: 95% 22px;
  background-size: 16px;
  color: #000000;
}

.DateRangePickerInput {
  display: flex;
  align-items: center;
  border: none;
}

.DateInput input[type="text"] {
  padding: 11px 14px 0 16px;
}

.DateInput_input {
  padding: 0px 30px 0;
  font-size: 16px;
}

/* .highcharts-container {
  width: 100% !important;
} */

/* .highcharts-root {
  width: 100% !important;
} */

@media only screen and (min-width: 310px) and (max-width: 767px) {
  /*=========================index page mobile=========================*/
  .dash_link {
    display: block;
    margin-bottom: 35px;
    padding-left: 20px;
  }

  .drop_row .drop_col select {
    font-size: 14px;
  }

  .insight_sec {
    padding-bottom: 50px;
  }

  .analys_row .drop_row {
    padding: 0px;
  }
  .camp_sec {
    flex-direction: column;
    padding: 0px 20px;
  }

  .camp_sec .camp_lft h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }

  .camp_sec .camp_rgt {
    margin-top: 20px;
  }

  .camp_sec .camp_lft,
  .camp_sec .camp_rgt {
    width: 100%;
    justify-content: flex-start;
  }

  .drop_row,
  .graph_row {
    flex-direction: column;
  }

  .dash_box_row {
    flex-wrap: wrap;
    justify-content: center;
  }

  .dash_box_row .dash_box_col {
    /* width: 47%; */
    height: auto;
    min-height: 208px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 10px 15px;
  }

  .dash_box_col > span.pointer {
    /* font-size: 34px; */
  }

  .dash_cont > p.box_head {
    word-break: break-all;
  }

  .dash_box_col > p.box_head {
    margin-bottom: 15px;
  }

  .drop_row .drop_col {
    width: 100%;
    margin-bottom: 20px;
  }

  .drop_row {
    padding: 0px 10px;
  }

  .drop_row .drop_col.col_ext {
    width: 100%;
  }

  .graph_col {
    width: 100% !important;
  }

  /* .graph_col > div {
    overflow: auto;
  } */

  .graph_col > div {
    overflow: auto !important;
  }

  .graph_row {
    margin-top: 20px;
  }

  /* ====== Slider dots ====== */
  .dash_box_row .slick-dots,
  .graph_row .slick-dots {
    display: block;
    background: #c4c4c4;
    border-radius: 100px;
    width: auto;
    transform: translate(-50%, 50%);
    left: 50%;
    bottom: -18px;
  }

  .dash_box_row .slick-dots li.slick-active button:before,
  .graph_row .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #B4FF00;
    font-size: 12px;
    top: -2px;
  }
  .dash_box_row .slick-dots li button:before,
  .graph_row .slick-dots li button:before {
    opacity: 0.75;
    color: #e5e5e5;
    font-size: 12px;
    top: -2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1250px) {
  /*=========================index page ipad=====================*/
  .insight_sec {
    padding: 30px;
  }

  .camp_sec {
    flex-wrap: wrap;
    margin-bottom: 40px;
  }

  .camp_sec .camp_lft h3 {
    margin-bottom: 10px;
  }

  .camp_sec .camp_rgt {
    margin-top: 20px;
  }

  .camp_sec .camp_lft,
  .camp_sec .camp_rgt {
    width: 50%;
    justify-content: center;
  }

  .dash_box_row,
  .drop_row,
  .graph_row {
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .drop_row .drop_col {
    width: 47%;
    margin-bottom: 20px;
  }

  .dash_box_row .dash_box_col {
    /* width: 47%; */
    height: auto;
    min-height: 208px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .dash_box_col > span.pointer {
    font-size: 34px;
  }

  .dash_cont > p.box_head {
    word-break: break-all;
  }

  .dash_box_col > p.box_head {
    margin-bottom: 15px;
  }

  .drop_row .drop_col.col_ext {
    width: 47%;
  }

  .graph_col {
    /* width: 50%; */
  }

  /* ====== Slider dots ====== */
  .dash_box_row .slick-dots,
  .graph_row .slick-dots {
    display: block;
    background: #c4c4c4;
    border-radius: 100px;
    width: auto;
    transform: translate(-50%, 50%);
    left: 50%;
    bottom: -18px;
  }

  .dash_box_row .slick-dots li.slick-active button:before,
  .graph_row .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #726c6c;
    font-size: 12px;
    top: -2px;
  }
  .dash_box_row .slick-dots li button:before,
  .graph_row .slick-dots li button:before {
    opacity: 0.75;
    color: #e5e5e5;
    font-size: 12px;
    top: -2px;
  }
}

@media only screen and (max-width:600px){
  .dash_box_col > span.pointer{
    font-size: 20px;
  }
}