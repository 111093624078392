.createcardsteps__curtain {
    width: 100vw;
    height: 100vh;
    background-color: rgba(41, 41, 41, 0.7);
    position: fixed;
    z-index: 500;
    top: 0;
    left: 0;
}

.createcardsteps__card--container {
  position: fixed;
  height: auto;
  width: 100%;
  top: 0;
  left: 10%;
  z-index: 900;
}