/*======================frames=====================================*/
.analys_frame {
  padding-bottom: 150px;
  clear: both;
}

.frame_row {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}

.frame_row .frame_left_col {
  width: 100%;
  max-width: 35%;
  margin-right: 15px;
}

.frame_row .frame_left_col h2 {
  line-height: 29px;
  color: #000000;
  text-transform: uppercase;
}

.frame_row .frame_rgt_col {
  width: 100%;
  max-width: 75%;
  padding-right: 100px;
}

.frame_row .frame_left_col span {
  display: block;
  font-weight: normal;
  margin-top: 10px;
  font-size: 16px;
  line-height: 20px;
}

.frame_row .frame_left_col .frame_sub_head {
  margin: 30px 0px;
}

.frame_box_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.show_more {
  display: none;
}

.frame_box_wrap .frame_box {
  width: 181px;
  height: 180px;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 35px 20px 20px 30px;
  margin: 0 12px 20px;
}

.frame_box_wrap .frame_box h1 {
  font-weight: bold;
  font-size: 44px;
  line-height: 54px;
  color: #8d00fa;
  margin-bottom: 20px;
}

.frame_box_wrap .frame_box h4 {
  font-weight: normal;
  color: #000000;
  line-height: 17px;
}

.frame_box_wrap .green_head h1 {
  color: #b4ff00;
}

.frame_btm_wrap .frame_box_btm {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 25px 30px;
  margin-left: 10px;
  width: 592px;
  margin-bottom: 20px;
}

.frame_btm_wrap .frame_box_btm .frame_box_left img {
  margin-right: 25px;
  width: 56px;
  height: 56px;
}

.frame_btm_wrap .frame_box_btm .frame_box_rgt h3 {
  font-size: 16px;
  line-height: 20px;
  color: #8d00fa;
  margin-bottom: 10px;
}

.frame_btm_wrap .frame_box_btm .frame_box_rgt h4 {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.frame_heading h4 {
  position: relative;
  text-align: center;
  color: #f1f1f1;
  font-size: 12px;
  margin-top: 10px;
}

.frame_heading h4:before {
  content: "";
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.15);
  width: 45%;
  right: 0px;
  top: 10px;
}

.frame_heading h4:after {
  content: "";
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.15);
  width: 45%;
  left: 0px;
  top: 10px;
}

.analys_row .drop_row {
  margin-bottom: 10px;
}

.analys_row .drop_row .drop_col {
  width: 30%;
}

.analys_row h2 {
  margin-bottom: 30px;
  color: #000000;
  line-height: 29px;
}

.display_desktop_none {
  display: none;
}

@media only screen and (min-width: 310px) and (max-width: 767px) {
  /*=========================frames page mobile==============================*/
  .mob_dply_none {
    display: none;
  }

  .display_desktop_none {
    display: flex;
  }

  .show_more {
    display: inline-block;
    color: #179bfb !important;
    font-weight: 600;
    margin-left: 10px;
  }

  .analys_frame {
    padding-bottom: 50px;
  }

  .frame_row .frame_left_col .frame_sub_head {
    padding-right: 30px;
  }

  .analys_row h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .frame_row .frame_left_col h2 {
    font-size: 18px;
  }

  .analys_row {
    padding: 20px;
  }

  .analys_row .drop_row .drop_col {
    width: 100%;
  }

  .frame_row {
    flex-direction: column;
    padding: 20px;
  }

  .frame_box_wrap .frame_box h1 {
    font-size: 34px;
    margin-bottom: 10px;
  }

  .frame_box_wrap .frame_box h1 {
    font-size: 34px;
    margin-bottom: 10px;
  }

  .frame_heading h4:before,
  .frame_heading h4:after {
    width: 35%;
  }

  .frame_row .frame_left_col,
  .frame_row .frame_rgt_col {
    max-width: 100%;
    padding-right: 0;
  }

  .frame_box_wrap .frame_box {
    width: 100%;
    height: auto;
    min-height: 176px;
    padding: 25px 20px 20px 7px;
    /* margin: 0 10px 20px; */
    margin: 0px;
  }

  .frame_btm_wrap .frame_box_btm {
    width: 100%;
    padding: 25px 15px;
    margin: 0 0 20px;
  }

  /* ====== Slider dots ====== */
  .frame_row .slick-dots,
  .frame_row .slick-dots {
    display: block;
    background: #c4c4c4;
    border-radius: 100px;
    width: auto;
    transform: translate(-50%, 50%);
    left: 50%;
    bottom: -18px;
  }

  .frame_row .slick-dots li.slick-active button:before,
  .frame_row .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #726c6c;
    font-size: 12px;
    top: -2px;
  }
  .frame_row .slick-dots li button:before,
  .frame_row .slick-dots li button:before {
    opacity: 0.75;
    color: #e5e5e5;
    font-size: 12px;
    top: -2px;
  }

  .frame_btm_wrap {
    margin-top: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1250px) {
  /*=========================frames page ipad ==============================*/
  .mob_dply_none {
    display: none;
  }

  .display_desktop_none {
    display: flex;
  }

  .show_more {
    display: inline-block;
    color: #179bfb !important;
    font-weight: 600;
    margin-left: 10px;
  }

  .analys_frame {
    padding-bottom: 50px;
  }

  .frame_row .frame_left_col .frame_sub_head {
    padding-right: 30px;
  }

  .analys_row h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .frame_row .frame_left_col h2 {
    font-size: 18px;
  }

  .analys_row {
    padding: 20px;
  }

  .analys_row .drop_row .drop_col {
    width: 50%;
  }

  .frame_row {
    flex-direction: column;
    padding: 20px;
  }

  .frame_box_wrap .frame_box h1 {
    font-size: 34px;
    margin-bottom: 10px;
  }

  .frame_box_wrap .frame_box h1 {
    font-size: 34px;
    margin-bottom: 10px;
  }

  .frame_heading h4:before,
  .frame_heading h4:after {
    width: 35%;
  }

  .frame_row .frame_left_col,
  .frame_row .frame_rgt_col {
    max-width: 100%;
    padding-right: 0;
  }

  .frame_box_wrap .frame_box {
    width: 140px;
    height: auto;
    min-height: 145px;
    padding: 25px 20px 20px 30px;
  }

  .frame_btm_wrap {
    display: flex;
    justify-content: space-between;
  }

  .frame_btm_wrap .frame_box_btm {
    padding: 25px 15px;
    margin-top: 40px;
  }
  /* ====== Slider dots ====== */
  .frame_row .slick-dots,
  .frame_row .slick-dots {
    display: block;
    background: #c4c4c4;
    border-radius: 100px;
    width: auto;
    transform: translate(-50%, 50%);
    left: 50%;
    bottom: -18px;
  }

  .frame_row .slick-dots li.slick-active button:before,
  .frame_row .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #726c6c;
    font-size: 12px;
    top: -2px;
  }
  .frame_row .slick-dots li button:before,
  .frame_row .slick-dots li button:before {
    opacity: 0.75;
    color: #e5e5e5;
    font-size: 12px;
    top: -2px;
  }
  .frame_row .frame_left_col {
    padding-left: 20px;
  }
}
