.fbincludeaudience__heading--container {
  margin-left: 6%;
  display: flex;
  width: 90%;
  justify-content: space-between;
  font-weight: bold;
  font-size: 14px;
}

.fbincludeaudience__heading {
  width: 50%;
}
.fbincludeaudience-fb-icon{
  width: 30px;
  height: 30px;
  margin-right: 2%;
}

/* .fbincludeaudience__textarea {
  width: 90%;
  border: 1px solid #CCCCCC;
  margin-left: 5%;
  margin-top: 10px;
} */

.fbincludeaudience__input--select {
  width: 85%;
  margin-left: 6%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.fbincludeaudience__img--arrow {
  width: 20px;
  height: 20px;
  transform: rotate(90deg);
  margin-left: 10px;
}

.fbincludeaudience__para {
  margin-left: 6%;
  width: 85%;
}

.fbincludeaudience_span_hover_pointer {
  cursor: pointer;
}

@media only screen and (max-width:600px){
  .fbincludeaudience-fb-icon{
    width: 20px;
    height: 20px;
    
  }
  .fbincludeaudience__heading--container{
    font-size: 12px;
  }
}