.editanddelete_tooltip {
    position: relative;
    display: inline-block;
}

.editanddelete_tooltip a {
    text-decoration: none;
}

.editanddelete_tooltip .editanddelete_tooltiptext {
    width: 120px;
    background-color: #FFFFFF;
    /* color: #fff; */
    text-align: center;
    padding: 5px 0;
    /* Position the editanddelete_tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.editanddelete_tooltip .fa-trash,
.editanddelete_tooltip .fa-pencil {
    color: #8D02FA;
}

.btn-cancel {
    background-color: none;
    border: 1px solid purple;
    color: purple;
    border-radius: 20px;
}

.modal_container {
    text-align: center;
}