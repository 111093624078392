.predictionstats__container {
  position: relative;
  padding-bottom: 50px;
  padding-top: 20px;
}

.predictionstats__heading {
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin-left: 2%;
}

.predictionstats__span--colored {
  color: #8D00FA;
  display: block;
  margin-top: 20px;
  margin-left: 2%;
  font-size: 20px;
  font-weight: 500;
}

.predictionstats__span {
  display: block;
  margin-top: 10px;
  margin-left: 2%;
  font-size: 20px;
  font-weight: 500px;
}

.predictionstats__span::after {
  content: "";
  width: 90%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.15);
  position: absolute;
  bottom: 20px;
  left: 5%;
}

@media only screen and (max-width:600px){
  .predictionstats__span::after {
    display: none;
    
  }
  .predictionstats__span--colored{
    color: #000;
    font-weight: 400;
    font-size: 14px;
  }
  .predictionstats__span{
    color:#8D00FA;
    font-weight: 500;
    font-size: 14px;
  }
  
}