.sidebar ul{
  padding: 0;
  margin: 0;
}
.sidebar li{list-style:none;font-size:14px;
margin-bottom: 10px;}

.sidebar {
  padding-top: 45px;
  position: absolute;
  left: 120px;
  top: 0px;
  width: 200px;
  z-index: 1;
  bottom: 0;
  background: #f9f9f9;
  height: 100vh;
}

.screen-mainbox{   position: relative;
  padding-left: 200px;} 
.sidebar li a {
  border-left: 8px solid transparent;
  padding: 4px 20px;
  display: block;
  color: #000;
  text-decoration: none;
}
.sidebar li.active a{border-left:8px solid #b4ff00; 
  font-weight:700;
}
.profile-dashboard {
  margin: 0; padding: 60px 10% 40px 35px;

}
.Right-Wrapper{
  padding: 60px 10% 40px 125px;
  background: #fff;
  min-height: 95vh;
}
.main-heading{
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 30px;
}
.faq-answers.w--open{
  width: 100% !important;
}