.selectpostpopup__container {
  width: 70%;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 999;
  padding-bottom: 15px;
  border-radius: 5px;
}

.selectpostpopup__heading {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e7ea;
  padding-left: 5%;
  padding-right: 3%;
  padding-top: 15px;
  font-weight: bold;
  font-size: 16px;
}

.selectpostpopup__container--btn {
  padding-top: 10px;
  float: right;
  margin-right: 5%;
  display: block;
  font-size: 12px;
  width: 90%;
}

.selectpostpopup__btn--grey {
  font-weight: bold;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid #dddddd;
}

.selectpostpopup__btn--blue {
  font-weight: bold;
  background-color: #1876d2;
  color: #fff;
  border: 2px solid #1876d2;
  margin-left: 20px;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 2px;
}

.selectpostpopup__icon--close {
  font-size: 20px;
  margin-top: 2px;
  cursor: pointer;
}

/* ------- wasim css ------- */

.uplaod-preview img,
video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.dropzone:focus {
  outline: none;
}
.dropzone {
  /* height: 114px !important; */
  /* min-height: 114px !important; */
}

.drag-drop-container {
  /* height: 688px; */
}

.drag-drop-container.height-auto {
  /* height: 810px !important; */
}

/* ------ Upload Modal ------ */
.uploadContent {
  border: 1px solid #8d00fa;
  border-radius: 8px;
  text-align: center;
  padding: 25px;
  margin: 40px 30px;
  cursor: pointer;
}
.uploadContent h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000;
}

.uploadContent p {
  font-size: 13px;
  color: #707070;
  line-height: 16px;
}

.uploadContent .highLight {
  text-decoration: underline;
  color: #8d00fa;
  font-weight: 600;
}

.uploadDcontent {
  text-align: left;
  display: flex;
}

.uploadcontentdetails {
  margin-top: 5px;
}

.uploadcontentdetails p {
  margin: 0;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 15px;
  color: #adacac;
}

.uploadCicon {
  margin-right: 10px;
}

.uploadcontentdetails h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000;
}

.uploadDetails {
  margin: -25px 30px;
  margin-bottom: 15px;
}

.modalFooterBtn {
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-bottom: 10px;
}

.modalFooterBtn li + li {
  margin-left: 15px;
}

.modalFooterBtn .btn {
  padding: 0.775rem 3rem;
  border-radius: 40px !important;
  font-weight: bold;
}

.modalFooterBtn .btn.btn-primary {
  background: #8d02fa;
  color: white;
  border: 0px;
  border: 1px solid #8d02fa;
  border-radius: 20px;
}

.datatSortable {
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0;
  flex-wrap: wrap;
}

.datatSortable li {
  margin: 10px;
  width: 120px;
  overflow: hidden;
  height: 100px;
  border: 1px solid #222222 !important;
  padding: 4px;
}

@media only screen and (max-width: 700px) {
  .selectpostpopup__container--btn {
    font-size: 10px;
  }
  .selectpostpopup__btn--grey {
    padding: 3px 10px;
  }
  .selectpostpopup__btn--blue {
    padding: 3px 10px;
  }
}
