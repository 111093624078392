.errormessage {
  /* height: 45vh; */
  margin: auto;
  text-align: center;
  align-items: center;
}

.errormessage__warning--large {
  display: inline-block;
  font-size: 5rem;
  color: blueviolet;
}
