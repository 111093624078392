.box-div{
  padding: 20px;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  overflow: auto;
  cursor: pointer;
}
.box-div-active{
  box-shadow: #8d25f685 0px 2px 8px 0px;
  background-color: #fbf8ff;
}
.MuiGrid-container.box-container{
  /* background-color: rgb(235 235 235); */
}
.type1{
  display: flex;
  justify-content:flex-start;
  align-items: center;
}
.type1 img{
  width: 60px;
  margin-right: 10px;
}
.type2 img{
  width: 60px;
}
.title{
  font-weight: 600;
  font-size: 1.1rem;
  word-break:normal;
}
.value{
  font-size: 1.2rem;
  font-weight: 600;
  color: #8c25f6;
}
.percentage{
  font-size: 14px;
  font-weight: 500;
}
@media screen and (max-width:600px){
  .type1{
      display: block;
 }
}
@media screen and (max-width:600px){
  .type1 img{
      width: 40px;
      margin-right: 10px;
 }
  .type2 img{
      width: 40px;
 }
  .title{
      font-weight: 600;
      font-size: .9rem;
      word-break: break-all;
 }
  .value{
      font-size: .9rem;
      font-weight: 600;
      color: #8c25f6;
 }
  .percentage{
      font-size: 12px;
      font-weight: 500;
 }
}


/* RMF chart styles start  */
.RMF_Loader_container{
  height: 526px;
}
#chart_div{
  width: 100%;
  /* min-height: 500px; */
  height: 532px;
}
#chart_div div div div svg g text {
  font-size: 18px;
}
.percent_text {
  font-size: 15px !important;
}

/* RMF chart styles end */

