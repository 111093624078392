h2 {
  font-size: 24px;
  font-weight: bold;
}

h3 {
  font-size: 15px;
  font-weight: bold;
}

h4 {
  font-size: 14px;
  font-weight: bold;
}

h5 {
  font-size: 12px;
  color: #999;
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  margin: 0px;
  padding: 0px;
}

ul {
  list-style: none;
}

/* img {
  width: 100%;
  object-fit: cover;
} */

a,
a:hover {
  text-decoration: none;
  outline: none;
}

/*.container {
width: 100%;
padding-right: 15px;
padding-left: 15px;
margin-right: auto;
margin-left: auto;
}
*/
.cstm_container {
  width: 100%;
  padding: 0px 20px;
}

.width100 {
  width: 100%;
  float: left;
}

/* section {
  width: 100%;
  float: left;
} */

.pos_rel {
  position: relative;
}

.wrapper{
  padding: 5px 5px 5px 120px;
}

.frame_sub_head p{
  word-break: break-all;
    text-align: justify;
}

@media only screen and (max-width:600px){
  .wrapper{
    padding: 5px;
  }
}