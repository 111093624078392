.precent_report p {
    margin-bottom: 0px;
}

.outer_campaign p {
    margin: 0;
}

#edit_section .delete_camp {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
}

.info_campaign, .expire {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.outer_campaign #edit_section, .outer_campaign .operation_section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}


.cmpname {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 12px; 
    word-break: break-all;
    color: #000000;
}

.campaign_list_title h2 {
    margin: 25px 0px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

#campaign_list ul .outer_campaign {
    background: #FFFFFF;
    border: 1px solid #DEE2E6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 30px 15px;
    margin: 20px 0px;
}

#campaign_list ul {
    list-style: none;
    padding: 0;
}

#campaign_list .outer_campaign .budget_info {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #A5A5A5;
    margin-bottom: 10px !important;
}


.statatics .col-md-2 h5 {
    margin-bottom: 20px;
    margin-top: 25px;
}

.precent_report {
    padding: 19px 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #000000;
    border-radius: 50%;
    border: 3px solid #B0B0B0;
    width: 75px;
    margin: 11px auto;
}

.statatics h5 {
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    letter-spacing: 0.0025em;
    margin-bottom: 0;
    color: #000000;
}

#overview-section {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.brand-name {
    /* padding-left: 35px; */
    display: flex;
    align-items: center;
}


.back_home a {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #5F5F5F;
}

.mt-40 {
    margin-top: 40px;
}

#overview-section .brand-name p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 26.2px;
    line-height: 32px;
    letter-spacing: 0.0025em;
    color: #7D7D7D;
}

.dropdown-option{
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 5px;
  }

.status-select .MuiInputLabel-outlined{ 
    transform: translate(14px, 14px) scale(1);
}

.status-select .MuiOutlinedInput-input  {
    padding: 8.5px 14px;
    font-size: 12px;
}
.status-select .MuiInputBase-root{
    font-weight: 500px;
}
  
  .campaign-list-header #overview-section{
    border: none;
      box-shadow: none;
  }
  
  .campaign-list-header .card-body{
    /* padding-left: 0px;
    padding-right: 0px; */
    padding: 0px;
    padding-bottom: 10px;
    
  }

  .cell.MuiTableCell-root{
    /* padding: 0px; */
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .filter{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 280px;
    overflow: auto;
  }
  .filter .BrandOverview-formControl-33{
      margin-right: 0px;
  }

  .font-grey{
    color: grey;
    font-size: 11px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .cell{
    flex: 3;
    padding-left: 10px;
  }
  .cell:nth-child(2){
    flex: 2;
}
  .cell:first-child{
    flex: 2;
  }

  .cell:last-child{
    flex: 1;
  }
  
  